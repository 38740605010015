import {
  Alert,
  Box,
  Divider,
  Snackbar,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { hideToast, setClosed } from "../slices/error";
import { setUserInfo } from "../slices/user";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { getConfig, getSchema, getUser } from "../api/crud";
import { Outlet } from "react-router-dom";
import { updateConfig, updateSchema } from "../slices/appState";
import { GT_COLORS, GT_THEME } from "../styles/Styles";
import { isEmpty, useIframe } from "../utils";
import loadable from "@loadable/component";
import { Helmet } from "react-helmet";
import ErrorBarrier from "../components/ErrorBarrier"; // Do not put this in component loadable
import AuthBearer from "../components/AuthBearer"; // Do not put this in component loadable
import { Logtail } from "@logtail/browser";

const TEXT = require("../text.json");

const LinearProgress = loadable(() =>
  import(/* webpackPrefetch: true */ "../components/LinearProgress")
);
const ErrorModal = loadable(() =>
  import(/* webpackPrefetch: true */ "../components/ErrorModal")
);

function RootErrorToast() {
  // @ts-ignore
  const isToastShown = useSelector((state) => state.error.isToastShown);
  // @ts-ignore
  const toastMessage = useSelector((state) => state.error.toastMessage);
  // @ts-ignore
  const toastSeverity = useSelector((state) => state.error.toastSeverity);

  const toastAutoHideDuration = useSelector(
    // @ts-ignore
    (state) => state.error.toastAutoHideDuration
  );

  const dispatch = useDispatch();
  return (
    <Snackbar
      open={isToastShown}
      autoHideDuration={toastAutoHideDuration}
      onClose={() => dispatch(hideToast())}
      sx={{ borderRadius: "5px" }}
    >
      <Alert
        //variant="filled"
        onClose={() => dispatch(hideToast())}
        severity={toastSeverity}
        elevation={8}
        sx={{
          width: "100%",
          border: `2px solid ${GT_THEME.palette[toastSeverity].light}`,
          borderRadius: "6px",
        }}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  );
}

function RootErrorModal() {
  // @ts-ignore
  const isModalOpen = useSelector((state) => state.error.isModalOpen);
  // @ts-ignore
  const errorTitle = useSelector((state) => state.error.title);
  // @ts-ignore
  const errorMessage = useSelector((state) => state.error.message);
  // @ts-ignore
  const errorModalExtra = useSelector((state) => state.error.extra);

  const dispatch = useDispatch();
  return (
    <ErrorModal
      open={isModalOpen}
      title={errorTitle}
      message={errorMessage}
      handleClose={() => {
        dispatch(setClosed());
      }}
    >
      <Stack>
        {Object.entries(errorModalExtra).map((key, value) => {
          return (
            <Typography sx={{ fontSize: ".8rem" }}>
              <b>{key}:</b> {value}
            </Typography>
          );
        })}
      </Stack>
    </ErrorModal>
  );
}

/**
 * Pulls schema and system config and stores them in the app state.
 */
const useUpdateMeta = () => {
  // @ts-ignore
  const schema = useSelector((state) => state.appState.schema);
  // @ts-ignore
  const config = useSelector((state) => state.appState.config);
  // @ts-ignore
  const userInfo = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    const timestamp = Date.now();

    if (
      userInfo.isTeacher &&
      (!config?.expire || config?.expire < timestamp || isEmpty(config?.data))
    ) {
      console.log("Fetching config...");
      getConfig({ dispatch: dispatch }).then((config) => {
        dispatch(updateConfig({ data: config }));
        console.log("Config updated");
      });
    }

    if (!schema?.expire || schema.expire < timestamp || isEmpty(schema?.data)) {
      console.log("Fetching schema...");
      getSchema({ dispatch: dispatch }).then((schema) => {
        dispatch(updateSchema({ data: schema }));
        console.log("Schema updated");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default function RootPage(props) {
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const yearString = useMemo(() => new Date().getFullYear().toString(), []);

  useUpdateMeta();
  const {sendCheckin} = useIframe()
  sendCheckin()

  if (isAuthenticated) {
    getAccessTokenSilently().then((token) => {
      console.log("Fetching user info...");
      getUser({ params: {}, authToken: token, dispatch: dispatch }).then(
        (info) => {
          dispatch(
            setUserInfo({
              userId: info.hash_id,
              firstName: info.first_name,
              lastName: info.last_name,
              isTeacher: info.is_teacher,
              groups: info.groups,
              organizations: info.organizations,
            })
          );
          console.log("User information set");

          if (window["agt_log_token"]) {
            const logtail = new Logtail(window["agt_log_token"]);
            logtail.info("User sign in", {
              user_id: info?.hash_id,
            });
          }
        }
      );
    });
  }

  const baseMeta = useMemo(() => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{TEXT.meta.title}</title>
        <meta name="keywords" content={TEXT.meta.keywords} />
        <meta name="description" content={TEXT.meta.description} />
        <meta name="author" content={TEXT.meta.author} />
      </Helmet>
    );
  }, []);
  let element;
  if (isLoading) {
    element = (
      <Box
        sx={{ width: "100vw" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {baseMeta}
        <Box sx={{ m: 4 }}>
          <LinearProgress />
        </Box>
      </Box>
    );
  } else if (!isAuthenticated) {
    element = <AuthBearer />;
  } else {
    element = (
      <ErrorBarrier>
        <Outlet />
        <RootErrorModal />
        <RootErrorToast />
      </ErrorBarrier>
    );
  }

  return (
    <ErrorBarrier>
      <Box
        id="root-box"
        sx={{ width: "100vw" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {baseMeta}
        {element}
        <footer
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              minWidth: "100vw",
              pt: 0.5,
              pb: 1,
              pl: 2,
              pr: 2,
              background: GT_COLORS.primaryLightBlue,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{ pr: 3, pl: 3 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.2}
                divider={
                  <Divider
                    orientation="vertical"
                    color={GT_COLORS.primaryGrey}
                    flexItem
                  />
                }
              >
                <Link
                  href="https://docs.graderthan.com/legal/privacy/"
                  underline="none"
                  fontSize={".8rem"}
                  color={GT_COLORS.primaryGrey}
                >
                  {TEXT.components.footer.privacy_title}
                </Link>
                <Link
                  href="https://docs.graderthan.com/legal/terms/"
                  underline="none"
                  fontSize={".8rem"}
                  color={GT_COLORS.primaryGrey}
                >
                  {TEXT.components.footer.terms_title}
                </Link>
              </Stack>
              <Stack direction="row">
                <Typography fontSize={".8rem"} color={GT_COLORS.primaryGrey}>
                  {TEXT.components.footer.copy_right.replace(
                    "{year}",
                    yearString
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </footer>
      </Box>
    </ErrorBarrier>
  );
}

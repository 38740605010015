import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { MODAL_STYLE } from "../styles/Styles";
import { CloseIcon } from "../styles/icons";
import IconButton from "./IconButton";
const TEXT = require("../text.json");

const BasicModal = ({
  children,
  open,
  handleClose,
  title,
  titleTools = [],
  buttonArray = [],
  sx = {},
}) => {
  const maxVerticalHeight = window.innerHeight;
  const style = {
    minWidth: 800,
    maxWidth: 1200,
    maxHeight: `${Math.round(maxVerticalHeight * 0.8)}px`,
    ...MODAL_STYLE,
    ...sx,
  };

  const titleContent = (
    <Box sx={{ mb: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Typography component="h1" noWrap sx={{ fontSize: "2rem" }}>
          {title}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {titleTools}
      </Stack>
      <Divider />
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        component="div"
        sx={style}
        justifyContent="center"
        alignItems="stretch"
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 0.5 }}
        >
          <IconButton
            onClick={handleClose}
            icon={CloseIcon}
            ariaLabel={TEXT.components.basic_modal.close_button_aria_label}
            size={"1.5rem"}
          />
        </Stack>
        {(title || titleTools) && titleContent}
        <Box
          id={"scroll-box"}
          component="div"
          sx={{
            //height: `${Math.round(maxVerticalHeight * 0.75)}px`,
            minHeight: "100px",
            maxHeight: `${Math.round(
              maxVerticalHeight * (buttonArray.length ? 0.7 : 0.75)
            )}px`,
            whiteSpace: "normal",
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
        {buttonArray.length ? (
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="flex-end"
            spacing={4}
            sx={{ mb: 2, mt: 3 }}
          >
            {buttonArray}
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
    </Modal>
  );
};

// BasicModal.propTypes = {
//   children: PropTypes.element.isRequired,
//   open: PropTypes.bool.isRequired,
//   handleClose: PropTypes.func.isRequired,
//   title: PropTypes.string,
//   titleTools: PropTypes.any,
//   sx: PropTypes.object,
//   buttonArray: PropTypes.array,
// };

export default BasicModal;

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import appStateReducer from "./slices/appState";
import userReducer from "./slices/user";
import errorReducer from "./slices/error";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createCompressor from "redux-persist-transform-compress";
import { persistStore } from "redux-persist";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const compressor = createCompressor();

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  //transforms: [compressor],
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);
const persistedAppStateReducer = persistReducer(persistConfig, appStateReducer);

export default () => {
  let store = configureStore({
    reducer: {
      appState: persistedAppStateReducer,
      user: persistedUserReducer,
      error: errorReducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });
  let persistor = persistStore(store);
  return { store, persistor };
};

// export default configureStore({
//   reducer: {
//     appState: persistedAppStateReducer,
//     user: persistedUserReducer,
//     error: errorReducer,
//   },
// });

import {
  TextField,
  Autocomplete,
  CircularProgress,
  debounce,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { matchSorter } from "match-sorter";
import { getProblemSet } from "../../api/crud";
import { get } from "lodash";
import PropTypes from "prop-types";

const TEXT = require("../../text.json");

/**
 * ProblemSetSearchField is an autocomplete search field component for problem
 * sets. It allows the user to search and select problem sets, with the option
 * to exclude certain sets.
 *
 * @param {object} props Component props
 * @param {string[]} [props.excludeProblemSets=[]] Array of problem set IDs to
 * be excluded from the search results.
 * @param {function} [props.onChange=() => {}] Callback function to handle
 * changes in the selected value.
 * @param {object} [props.sx={}] Style overrides for the component.
 */
const ProblemSetSearchField = ({
  excludeProblemSets = [],
  onChange = () => {},
  sx = {},
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [, setHasOptions] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);

  const fetch = useMemo(
    () =>
      debounce(async (request, callback) => {
        const token = await getAccessTokenSilently();
        request.authToken = token;
        getProblemSet(request).then((problemSets) => callback(problemSets));
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    const stringFilter = {};
    if (inputValue.length >= 3) {
      stringFilter.str_match = inputValue;
    }

    fetch(
      {
        params: {
          limit: 100,
          ...stringFilter,
        },
        dispatch: dispatch,
      },
      (problemSets) => {
        console.log("excludeProblemSets => ",excludeProblemSets)
        const problemSetOptions = problemSets.filter(
          (problemSet) => !excludeProblemSets.includes(problemSet.id)
        );

        setHasOptions(Boolean(problemSetOptions.length));

        if (active) {
          setOptions([...problemSetOptions]);
          setLoading(false);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const filterOptions = (options, { inputValue }) => {
    const ret = matchSorter(options, inputValue, { keys: ["name"] });
    return ret;
  };

  return (
    <Autocomplete
      id="asynchronous-problem-set-dropdown"
      sx={{ minWidth: "80%", ...sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, v) => {
        onChange(get(v, "id", undefined));
      }}
      onInputChange={(event, newInputValue) => {
        setLoading(true);
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => option.name}
      noOptionsText="No Problem Sets found"
      filterOptions={filterOptions}
      options={options}
      loading={loading}
      autoComplete
      includeInputInList
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            TEXT.components.problem_set.create_problem
              .existing_problems_autocomplete_label
          }
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  // @ts-ignore
                  <CircularProgress color="primaryLightBlue" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={"auto-complete-option-" + option.id}>
            {option.name}
          </li>
        );
      }}
    />
  );
};

ProblemSetSearchField.propTypes = {
  excludeProblemSets: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

ProblemSetSearchField.defaultProps = {
  excludeProblemSets: [],
  onChange: () => {},
  sx: {},
};

export default ProblemSetSearchField;

import Axios, { AxiosError } from "axios";
import { setupCache, buildMemoryStorage } from "axios-cache-interceptor";
import qs from "qs";
import { setOpen, setInfo } from "../slices/error";
const TEXT = require("../text.json");

const axios = setupCache(Axios, {
  storage: buildMemoryStorage(false, 10 * 1000, 256),
  methods: ["get"],
  ttl: 30 * 1000, // 30 seconds
  cachePredicate: {statusCheck: (status) =>   200 <= status && status <= 399},
});

/**
 * Wraps a promise to enable React Suspense to wait until the promise resolves.
 * @param {Promise} promise - The promise to be wrapped.
 * @returns {object} - An object with a `read` method that allows React Suspense to handle the promise.
 */
export function wrapPromise(promise) {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    /**
     * Reads the result of the wrapped promise.
     * If the promise is still pending, it throws the suspender object to let React Suspense handle it.
     * If the promise is rejected, it throws the error result.
     * If the promise is resolved, it returns the success result.
     * @throws {Promise} - The suspender object when the promise is pending.
     * @throws {any} - The error result when the promise is rejected.
     * @returns {any} - The success result when the promise is resolved.
     */
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
}

function makeAxiosConfig(inputs = {}) {
  const {
    path,
    authToken,
    cacheConfig = {},
    method = "get",
    baseURL = window["agt_api_http_base_url"] ,
    ...restParams
  } = inputs;

  const config = {
    headers: { Authorization: `Bearer ${authToken}` },
    cache: cacheConfig,
    method,
    baseURL,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    ...restParams,
  };

  // @ts-ignore
  console.debug(
    `[${config.method.toUpperCase()}] ${config.baseURL} -`,
    // @ts-ignore
    config.params || config.data
  );

  delete config["authToken"];
  delete config["cacheConfig"];

  return config;
}

export const handleRequestError = (dispatch, error) => {
  console.error(
    "Handled Request Error",
    JSON.stringify(error.response || error, null, 4)
  );

  if (error instanceof AxiosError) {
    let message = "";
    if (error?.response?.data?.detail instanceof Array) {
      error?.response?.data?.detail.forEach((detail) => {
        message += detail.loc[0] + " - " + detail.msg + "\n";
      });
    } else if (error?.response?.data?.detail) {
      message = error.response.data.detail;
    } else if (TEXT.axios[error.code]) {
      message = TEXT.axios[error.code];
    }

    dispatch(
      setInfo({
        title: error?.response?.statusText || error.message,
        message: message,
      })
    );
  }
  dispatch(setOpen());
};

export async function getSubmission({
  params,
  authToken,
  dispatch,
  cacheConfig = {},
}) {
  const path = `/${window["agt_api_version"]}/problem/submission`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        params: params,
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );
    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return [];
  }
}

export async function getProblem({
  dispatch,
  params,
  authToken,
  cacheConfig = {},
}) {
  const path = `/${window["agt_api_version"]}/problem/problem`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        params: params,
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );
    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return [];
  }
}

export async function getProblemSet({
  dispatch,
  params,
  authToken,
  cacheConfig = {},
}) {
  const path = `/${window["agt_api_version"]}/problem/problem-set`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        params: params,
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );
    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return [];
  }
}

export async function deleteProblemSet({ dispatch, ids, authToken }) {
  const path = `/${window["agt_api_version"]}/problem/problem-set`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "delete",
        params: { resource_id: ids },
        baseURL: url.toString(),
        authToken: authToken,
      })
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return [];
  }
}

export async function postProblemSet({ dispatch, authToken, data = {} }) {
  const path = `/${window["agt_api_version"]}/problem/problem-set`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "post",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function patchProblemSet({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/problem/problem-set`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "patch",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return {};
  }
}

export async function postProblem({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/problem/problem`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "post",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function patchProblem({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/problem/problem`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "patch",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return {};
  }
}

export async function getCourseConfig({
  params,
  authToken,
  dispatch,
  cacheConfig = {},
}) {
  const path = `/${window["agt_api_version"]}/grading/course-config`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        params: params,
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );

    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return [];
  }
}

export async function postCourseConfig({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/grading/course-config`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "post",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function patchCourseConfig({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/grading/course-config`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "patch",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function deleteCourseConfig({ ids, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/grading/course-config`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "delete",
        params: { resource_id: ids },
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function getGradeFormula({
  params,
  authToken,
  dispatch,
  cacheConfig = {},
}) {
  const path = `/${window["agt_api_version"]}/grading/grade-formula`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        params: params,
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function patchGradeFormula({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/grading/grade-formula`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "patch",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function postGradeFormula({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/grading/grade-formula`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "post",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function deleteGradeFormula({ ids, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/grading/grade-formula`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "delete",
        params: { resource_id: ids },
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function patchSubmission({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/problem/submission`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "patch",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function postSubmission({ data, authToken, dispatch }) {
  const path = `/${window["agt_api_version"]}/problem/submit`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "post",
        data: data,
        baseURL: url.toString(),
        authToken: authToken,
      })
    );

    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return null;
  }
}

export async function getSchema({
  dispatch,
  cacheConfig = { ttl: 10 * 60 * 1000 },
}) {
  const path = `/${window["agt_api_version"]}/meta/schema`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        baseURL: url.toString(),
        cacheConfig: cacheConfig,
      })
    );

    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return {};
  }
}

export async function getConfig({
  dispatch,
  cacheConfig = { ttl: 10 * 60 * 1000 },
}) {
  const path = `/${window["agt_api_version"]}/meta/config`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        baseURL: url.toString(),
        cacheConfig: cacheConfig,
      })
    );

    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return {};
  }
}

export const getTest = async ({
  problemId,
  testId,
  authToken,
  dispatch,
  cacheConfig = {},
}) => {
  const problems = await getProblem({
    dispatch: dispatch,
    params: { resource_id: problemId },
    authToken: authToken,
    cacheConfig: cacheConfig,
  });

  if (!problems.length)
    throw Error(`There is no problem associated with problem ID ${problemId}`);
  const problem = problems[0];
  const toRet = problem.tests.find((test) => test.test_id === testId);
  if (!toRet)
    throw Error(
      `The problem ${problemId} has no test associated with the test ID ${testId}`
    );
  return toRet;
};

export async function getGroup({
  params,
  authToken,
  dispatch,
  cacheConfig = {},
}) {
  const path = `/${window["agt_api_version"]}/user/group/${params.groupId}`;
  const url = new URL(path, window["agt_api_http_base_url"] );
  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );

    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return {};
  }
}

export async function getGroupRoster({
  params,
  authToken,
  dispatch,
  cacheConfig = {},
}) {
  if (!params.groupId) {
    throw Error("missing group ID in parameter");
  }

  const path = `/${window["agt_api_version"]}/user/group/${params.groupId}/roster`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );

    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return {};
  }
}

export async function getGroups({ authToken, dispatch, cacheConfig = {} }) {
  const path = `/${window["agt_api_version"]}/user/groups/`;
  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );

    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return {};
  }
}

export async function getUser({
  params = null,
  authToken,
  dispatch,
  cacheConfig = {},
}) {
  let path = `/${window["agt_api_version"]}/user/user/`;

  if (params?.user_id) {
    path += params.user_id;
  }

  const url = new URL(path, window["agt_api_http_base_url"] );

  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        baseURL: url.toString(),
        authToken: authToken,
        cacheConfig: cacheConfig,
      })
    );

    console.debug(
      `[${response.status}]  Cache: ` + (response.cached ? "hit" : "miss")
    );
    return response.data;
  } catch (e) {
    handleRequestError(dispatch, e);
    return {};
  }
}

export async function checkFrontEndConnectivity() {
  try {
    const response = await axios(
      makeAxiosConfig({
        method: "get",
        baseURL: window["agt_api_http_base_url"] ,
      })
    );
    return 200 <= response.status || response.status <= 399;
  } catch (e) {
    return false;
  }
}

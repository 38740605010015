import { createSlice } from "@reduxjs/toolkit";

const TEXT = require("../text.json");

export const errorSlice = createSlice({
  name: "error",
  initialState: {
    isModalOpen: false,
    title: TEXT.components.error_modal.title,
    message: TEXT.components.error_modal.message,
    extra: {},
    isToastShown: false,
    toastMessage: TEXT.error.general_issue_message_toast,
    toastSeverity: "error",
    toastAutoHideDuration: 10 * 1000,
  },
  reducers: {
    setOpen: (state) => {
      state.isModalOpen = true;
    },
    setClosed: (state) => {
      state.isModalOpen = false;
    },
    setInfo: (state, action) => {
      state.title = action.payload.title || TEXT.components.error_modal.title;
      state.message =
        action.payload.message || TEXT.components.error_modal.message;
      state.extra = action.payload.extra || {};
    },
    showToast: (state, action) => {
      state.toastMessage =
        action.payload.message || TEXT.error.general_issue_message_toast;
      state.toastSeverity = action.payload.severity || "error";
      state.toastAutoHideDuration =
        action.payload.autoHideDuration || 10 * 1000;
      state.isToastShown = true;
    },
    hideToast: (state) => {
      state.isToastShown = false;
    },
    setToastMessage: (state, action) => {
      state.toastMessage =
        action.payload.message || TEXT.error.general_issue_message_toast;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOpen,
  setClosed,
  setInfo,
  showToast,
  hideToast,
  setToastMessage,
} = errorSlice.actions;

export default errorSlice.reducer;

import { Button } from "@mui/material";
import { useState } from "react";
import AddProblemSetModal from "./AddProblemSetModal";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makePath } from "../../utils";

const TEXT = require("../../text.json");

/**
 * AddProblemSetButton is a component that renders a button to add a problem set.
 * When clicked, it opens a modal dialog for adding a new problem set to a course.
 *
 * @param {object} props Component props
 * @param {string} props.courseId The unique identifier for the course to which a problem set is being added.
 */
function AddProblemSetButton({ courseId }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const homeCourse = searchParams.get("homeCourse");

  return (
    <>
      <Button
        variant="contained"
        // @ts-ignore
        color="primaryLightBlue"
        onClick={() => setOpen(true)}
        fullWidth
      >
        {TEXT.components.course_schedule.add_problem_set_button.title}
      </Button>
      <AddProblemSetModal
        courseId={courseId}
        open={open}
        setClosed={() => setOpen(false)}
        onCreationComplete={(newCourseConfig) => {
          const navUrl = makePath(
            `/problem-set/${newCourseConfig.problem_set_id}/edit/general/`,
            searchParams
          );

          setOpen(false);
          navigate(navUrl);
        }}
      />
    </>
  );
}

AddProblemSetButton.propTypes = {
  courseId: PropTypes.string.isRequired, // courseId is required and should be a string
};

export default AddProblemSetButton;

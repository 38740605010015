import { createSlice } from "@reduxjs/toolkit";
import { INSTRUCTOR_MAIN } from "../pages/names";

export const appStateSlice = createSlice({
  name: "appState",
  initialState: {
    page: {
      name: INSTRUCTOR_MAIN,
      docId: "",
      extra: {},
    },
    privacyMode: true,
    studentPreviewMode: false,
    pinnedCourses: [],
    schema: {
      expire: 0,
      data: {},
    },
    config: {
      expire: 0,
      data: {},
    },
  },
  reducers: {
    pinCourse: (state, action) => {
      state.pinnedCourses = [action.payload.courseId, ...state.pinnedCourses];
    },

    unpinCourse: (state, action) => {
      state.pinnedCourses = state.pinnedCourses.filter(
        (course) => course !== action.payload.courseId
      );
    },

    updateSchema: (state, action) => {
      const timestamp = Date.now();
      state.schema.expire = timestamp + 5 * 60 * 1000; // expire in 5 min
      state.schema.data = action.payload.data;
    },

    updateConfig: (state, action) => {
      const timestamp = Date.now();
      state.config.expire = timestamp + 5 * 60 * 1000; // expire in 5 min
      state.config.data = action.payload.data;
    },

    togglePrivacyMode: (state) => {
      state.privacyMode = !state.privacyMode;
    },

    toggleStudentPreviewMode: (state) => {
      state.studentPreviewMode = !state.studentPreviewMode;
    },
    setStudentPreviewMode: (state, action) => {
      state.studentPreviewMode = action.payload.modeState;
    },
    setPrivacyMode: (state, action) => {
      state.privacyMode = action.payload.modeState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  togglePrivacyMode,
  pinCourse,
  unpinCourse,
  updateSchema,
  updateConfig,
  toggleStudentPreviewMode,
  setStudentPreviewMode,
  setPrivacyMode
} = appStateSlice.actions;

export default appStateSlice.reducer;

import { Stack, Typography, Paper, Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react"; // Added useState
import { GT_COLORS } from "../styles/Styles";
import { useAuth0 } from "@auth0/auth0-react";
import Barrier from "./Barrier"; // Do not put this in component loadable
import { LockIcon } from "../styles/icons";
import { makePath } from "../utils";

const TEXT = require("../text.json");

const LogoutBarrier = ({ pageTitle = "" }) => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [seconds, setSeconds] = useState(7); // Added state for seconds

  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = GT_COLORS.primaryLightBlue;

    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  useEffect(() => {
    // Set an interval to decrease seconds by 1 every second
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
    }, 1000);

    // If seconds reach 0, navigate to the home page
    if (seconds === 0) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }

    // Clear the interval when the component unmounts or seconds reach 0
    // @ts-ignore
    return () => clearInterval(interval);
  }, [seconds, navigate]);

  return useMemo(
    () => (
      <Barrier pageTitle={pageTitle}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ minWidth: "400px" }}
        >
          <Paper
            sx={{
              cursor: "pointer",
              background: "inherit",
              border: "solid",
              borderColor: "transparent",
              borderRadius: "none",
              borderWidth: "1px",
            }}
            elevation={0}
          >
            <LockIcon size={"5rem"} />
          </Paper>
          <Typography fontSize={"1.4rem"} align="center">
            {TEXT.logout_page.message.replace("{SECONDS}", seconds.toString())}{" "}
            {/* Updated message to reflect countdown */}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            // @ts-ignore
            color="primaryLightBlue"
            onClick={() => {
              navigate(makePath("/",searchParams));
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Barrier>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [seconds]
  );
};

export default LogoutBarrier;

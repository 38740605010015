import { Stack, Fade, Paper } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import Page from "./Page";
import { GT_COLORS } from "../styles/Styles";
import { makePath } from "../utils";
const TEXT = require("../text.json");

const Barrier = ({ pageTitle = "", children }) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    // Save the original background properties
    const originalBackground = document.body.style.background;
    const originalBackgroundSize = document.body.style.backgroundSize;
    const originalAnimation = document.body.style.animation;

    // Set gradient background styles
    document.body.style.background = `linear-gradient(-45deg, #9fc9fc, ${GT_COLORS.primaryLightBlue}, ${GT_COLORS.altMidBlue}, ${GT_COLORS.primaryDarkBlue}, #040835)`;
    document.body.style.backgroundSize = "400% 400%";
    document.body.style.animation = "gradient 45s ease infinite";

    // Inject CSS for @keyframes
    const styleSheet = document.createElement("style");
    styleSheet.innerHTML = `
      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `;
    document.head.appendChild(styleSheet);

    // Revert back to the original background styles when the component unmounts
    return () => {
      document.body.style.background = originalBackground;
      document.body.style.backgroundSize = originalBackgroundSize;
      document.body.style.animation = originalAnimation;
      document.head.removeChild(styleSheet); // Remove the injected styles
    };
  }, []);

  return useMemo(
    () => (
      <Page
        enableNavBar={false}
        enableBackButton
        backButtonTitle={TEXT.problem_set_editor.back_button_title}
        backButtonCallback={() => navigate(makePath("/", searchParams))}
        addBottomSpace={false}
        pageTitle={pageTitle}
      >
        <Fade in={true} timeout={700}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            sx={{
              width: 1,
            }}
          >
            <Paper elevation={6} sx={{ maxWidth: "60%", minWidth: "350px" }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{
                  m: 3,
                  p: 3,
                }}
              >
                {children}
              </Stack>
            </Paper>
          </Stack>
        </Fade>
      </Page>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children]
  );
};

export default Barrier;

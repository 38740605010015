import { Stack, Typography, Button, Paper } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { GT_COLORS } from "../styles/Styles";
import Barrier from "./Barrier"; // Do not put this in component loadable
import { makePath } from "../utils";

const TEXT = require("../text.json");

const GeneralBarrier = ({
  message,
  icon: Icon,
  reload = false,
  pageTitle = "",
}) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Save the original background color
    const originalBackgroundColor = document.body.style.backgroundColor;

    // Change the background color
    document.body.style.backgroundColor = GT_COLORS.primaryLightBlue;

    // Revert back to the original background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  return useMemo(
    () => (
      <Barrier pageTitle={pageTitle}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Paper
            sx={{
              cursor: "pointer",
              background: "inherit",
              border: "solid",
              borderColor: "transparent",
              borderRadius: "none",
              borderWidth: "1px",
            }}
            elevation={0}
          >
            <Icon size={"5rem"} />
          </Paper>
          <Typography fontSize={"1.4rem"} align="center">
            {message}
          </Typography>
          <Button
            onClick={() => {
              if (reload) {
                window.location.reload();
              } else {
                navigate(makePath("/",searchParams));
              }
            }}
            // @ts-ignore
            color="primaryLightBlue"
            variant="contained"
            size="large"
            sx={{ width: "40%" }}
          >
            {TEXT.general_barrier.home_button_title}
          </Button>
        </Stack>
      </Barrier>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export default GeneralBarrier;

import { createSlice } from "@reduxjs/toolkit";

function createEmptyUserInfo() {
  return {
    userId: "",
    firstName: "",
    lastName: "",
    isTeacher: true,
    groups: [],
    organizations: [],
  };
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    authToken: "",
    userInfo: createEmptyUserInfo(),
  },

  reducers: {
    clearState: (state) => {
      state.userInfo = createEmptyUserInfo();
    },

    setAuthToken: (state, action) => {
      state.authToken = action.payload.authToken;
    },

    setUserInfo: (state, action) => {
      state.userInfo.userId = action.payload.userId;
      state.userInfo.firstName = action.payload.firstName;
      state.userInfo.lastName = action.payload.lastName;
      state.userInfo.isTeacher = action.payload.isTeacher;
      state.userInfo.groups = action.payload.groups;
      state.userInfo.organizations = action.payload.organizations;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearState, setAuthToken, setUserInfo } = userSlice.actions;


export default userSlice.reducer;

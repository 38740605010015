import { default as MaterialIconButton } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider } from "@mui/material/styles";
import { GT_COLORS, GT_THEME } from "../styles/Styles";

function IconButton({
  icon,
  tooltip = "",
  ariaLabel="",
  onClick,
  size = ".9em",
  disabled = false,
  color=null
}) {
  var Icon = icon;

  return (
    <ThemeProvider theme={GT_THEME}>
      <Tooltip title={tooltip} describeChild arrow>
        <MaterialIconButton
          aria-label={ariaLabel || tooltip}
          onClick={onClick}
          disabled={disabled}
        >
          <Icon size={size} color={color || GT_COLORS.primaryDarkBlue} />
        </MaterialIconButton>
      </Tooltip>
    </ThemeProvider>
  );
}

export default IconButton;

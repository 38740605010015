import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Divider, Fade, Stack, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCallback, useMemo, useReducer, useRef, useState } from "react";
import {
  deleteCourseConfig,
  getCourseConfig,
  getGroup,
  patchCourseConfig,
} from "../api/crud";
import { useNavigate } from "react-router-dom";
import CourseSchedule from "../components/course-schedule/CourseSchedule";
import ContentLoader from "react-content-loader";
import { GT_COLORS, CONTENT_LOADER_DEFAULT_PROPS } from "../styles/Styles";
import Page from "./Page";
import { nextClosest } from "../components/course-schedule/utilities";
import { makePath, useStudentView } from "../utils";
import { isNil } from "lodash";

const TEXT = require("../text.json");

const CoursesSoloPage = ({
  courseId: internalCourseId = undefined,
  showBackButton = undefined,
}) => {
  // @ts-ignore
  const userInfo = useSelector((state) => state.user.userInfo);
  let { courseId } = useParams();
  courseId = internalCourseId || courseId;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courseInfo, setCourseInfo] = useState(
    userInfo.groups.find((c) => c.hash_id === courseId)
  );
  const courseScheduleForceUpdateRef = useRef(() => {});
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const isStudentView = useStudentView();
  let [searchParams, setSearchParams] = useSearchParams();

  // Cache bust so the refresh gets the latest changes
  const bustCache = useCallback(() => {
    return getAccessTokenSilently().then((token) => {
      getCourseConfig({
        params: {
          group_id: courseId,
        },
        authToken: token,
        dispatch: dispatch,
        cacheConfig: { override: true },
      });
    });
  }, [courseId, dispatch, getAccessTokenSilently]);

  // No course was found let's try to load it
  if (!courseInfo) {
    getAccessTokenSilently().then(async (token) => {
      getGroup({
        params: { groupId: [courseId] },
        authToken: token,
        dispatch: dispatch,
      }).then((newCourseInfo) => {
        // We found the course lets set it
        if (newCourseInfo?.hash_id) setCourseInfo(newCourseInfo);
        // We could not find the course so let's take them back home
        else navigate(makePath("/", searchParams));
      });
    });
  }

  const element = useMemo(() => {
    if (courseInfo) {
      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ m: 4, width: 1 }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.4}
          >
            <Typography
              noWrap
              component={"h1"}
              fontSize={"1.7rem"}
              fontWeight={700}
            >
              {courseInfo.name}
            </Typography>
            <Typography
              noWrap
              component={"p"}
              fontSize={".9rem"}
              fontWeight={700}
            >
              {TEXT.components.courses_page.course_solo.main_title}
            </Typography>
          </Stack>
          <CourseSchedule
            showAddProblemSetButtonOnEmpty={true}
            courseId={courseId}
            setCourseScheduleForceUpdateCallback={(callback) => {
              courseScheduleForceUpdateRef.current = callback;
            }}
            handleRemove={(courseConfig, completeCallback) => {
              console.debug(
                `Remove course config: ${courseConfig.id} in course: ${courseId}`
              );
              getAccessTokenSilently()
                .then(async (token) => {
                  await deleteCourseConfig({
                    ids: [courseConfig.id],
                    dispatch: dispatch,
                    authToken: token,
                  });
                  return token;
                })
                .then(async (token) => {
                  await bustCache();
                })
                .then(() => {
                  completeCallback();
                  forceUpdate();
                });
            }}
            handleMove={(
              courseConfigs,
              placement,
              courseConfig,
              completeCallback
            ) => {
              console.debug(
                `Move ${placement} course config: ${courseConfig.id} in course: ${courseId}`
              );

              if (courseConfigs.length <= 1) {
                console.debug(
                  "Skipped movement because there is only one config"
                );
                completeCallback();
                return;
              }

              const duplicate = JSON.parse(JSON.stringify(courseConfig));
              const orderArray = courseConfigs
                .filter((item) => item.id !== courseConfig.id)
                .map((item) => item.order);

              const newOrder = nextClosest(
                orderArray,
                courseConfigs.order || 0,
                placement === "up" ? -1 : 1
              );

              console.debug(`
            --------------
            orderArray: ${orderArray}
            newOrder:   ${newOrder}
            oldOrder:   ${duplicate.order}
            --------------
            `);

              if (newOrder === duplicate.order) {
                console.debug(
                  "Skipped movement because new order would be the same"
                );
                completeCallback();
                return;
              }

              duplicate.order = newOrder;

              getAccessTokenSilently()
                .then(async (token) => {
                  await patchCourseConfig({
                    data: duplicate,
                    authToken: token,
                    dispatch: dispatch,
                  });
                })
                .then(() => {
                  courseConfig.order = newOrder;
                  courseScheduleForceUpdateRef.current();
                });
            }}
          />
        </Stack>
      );
    } else {
      return (
        <Fade in={true} timeout={300}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{
              border: "solid",
              borderColor: GT_COLORS.primaryLightBlue,
              borderRadius: "5px",
              borderWidth: "1px",
              width: "90%",
              p: 2,
            }}
            divider={<Divider orientation="horizontal" flexItem />}
          >
            <ContentLoader
              style={{ width: "100%" }}
              {...CONTENT_LOADER_DEFAULT_PROPS}
            >
              <rect x="0" y="0" rx="3" ry="3" width="100%" height="50" />

              <rect x="0" y="90" rx="3" ry="3" width="100%" height="50" />

              <rect x="0" y="180" rx="3" ry="3" width="100%" height="50" />

              <rect x="0" y="270" rx="3" ry="3" width="100%" height="50" />
            </ContentLoader>
          </Stack>
        </Fade>
      );
    }
  }, [bustCache, courseId, courseInfo, dispatch, getAccessTokenSilently]);

  let enableBackButton = !searchParams.has("homeCourse");

  if (!isNil(showBackButton)) {
    enableBackButton = showBackButton;
  }

  return (
    <Page
      enableBackButton={enableBackButton}
      backButtonTitle={
        TEXT.components.courses_page.course_solo.back_button_title
      }
      backButtonTooltip={
        TEXT.components.courses_page.course_solo.back_button_tooltip
      }
      backButtonCallback={() => {
        navigate(makePath("/course", searchParams));
      }}
    >
      {element}
    </Page>
  );
};

export default CoursesSoloPage;

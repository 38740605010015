import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCourseConfig, getProblemSet } from "../../api/crud";
import { useDispatch } from "react-redux";
import {
  Box,
  Divider,
  Fade,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CONTENT_LOADER_DEFAULT_PROPS, GT_COLORS } from "../../styles/Styles";
import { TAB_GENERAL } from "../../pages/ProblemSetEditor";
import { sortCourseConfigs } from "./utilities";

import ProblemSetGrade from "../ProblemSetGrade";
import DueDate from "./DueDate";
import ScheduleOptionsMenu from "./ScheduleOptionsMenu";
import NoProblemSets from "./NoProblemSets";
import ContentLoader from "react-content-loader";
import { isAssignmentReleased, makePath, useStudentView } from "../../utils";
import IconButton from "../IconButton";
import { LinkIcon, StudentIcon } from "../../styles/icons";
import { showToast } from "../../slices/error";
import { setStudentPreviewMode } from "../../slices/appState";
import AddProblemSetButton from "./AddProblemSetButton";

const TEXT = require("../../text.json");

const CourseScheduleProblemSetRow = ({
  courseConfig,
  handleRemove = (courseConfig, completeCallback) => {},
  handleMove = (placement, courseConfig, completeCallback) => {},
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const problemSetRef = useRef(null);
  const loadingLockRef = useRef(false);
  const [mouseOver, setMouseOver] = useState(false);
  const isStudentView = useStudentView();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Skip if in the process of loading
    if (loadingLockRef.current) return;

    //Lock it
    loadingLockRef.current = true;

    setLoading(true);
    getAccessTokenSilently()
      .then((token) => {
        return getProblemSet({
          params: {
            resource_id: [courseConfig.problem_set_id],
          },
          authToken: token,
          dispatch: dispatch,
        });
      })
      .then((problemSets) => {
        problemSetRef.current = problemSets[0];
      })
      .finally(() => {
        setLoading(false);
        //Unlock loading lock
        setTimeout(() => (loadingLockRef.current = false), 10);
      });
  }, [courseConfig.problem_set_id, dispatch, getAccessTokenSilently, loading]);

  return useMemo(() => {
    if (loading || !problemSetRef.current) {
      return (
        <Fade in={true} timeout={300}>
          <Box>
            <ContentLoader
              style={{ width: "100%", height: "90px" }}
              {...CONTENT_LOADER_DEFAULT_PROPS}
            >
              <rect x="0" y="20" rx="3" ry="3" width="100%" height="50" />
            </ContentLoader>
          </Box>
        </Fade>
      );
    } else {
      const released = isAssignmentReleased(isStudentView, courseConfig);
      return (
        <Tooltip
          title={!released ? "This assignment has not been released yet." : ""}
          followCursor
        >
          <Paper
            onMouseEnter={() => {
              if (released) setMouseOver(true);
            }}
            onMouseLeave={() => {
              if (released) setMouseOver(false);
            }}
            sx={{
              cursor: released ? "pointer" : "not-allowed",
              background: mouseOver
                ? GT_COLORS.altMidGrey
                : GT_COLORS.primaryGrey,
            }}
            elevation={mouseOver ? 6 : 0}
            onClick={(e) => {
              if (released) {
                e.stopPropagation();
                if (!isStudentView) {
                  navigate(
                    makePath(`/problem-set/${courseConfig.problem_set_id}/edit/${TAB_GENERAL}`, searchParams)
                  );
                } else {
                  navigate(
                    makePath(`/problem-set/${courseConfig.problem_set_id}/submit/${courseConfig.group_id}`, searchParams)
                  );
                }
              }
            }}
          >
            <Box sx={{ p: 0.25, pl:1 }}>
              <Grid
                container
                //spacing={2}
                sx={{ m: 1 }}
                alignItems="center"
                justifyContent="center"
              >
                <Grid xs={4} item>
                  <Typography
                    noWrap
                    component="h3"
                    sx={{ fontSize: "1.2rem", fontWeight: 800 }}
                  >
                    {problemSetRef.current.name}
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <DueDate courseConfig={courseConfig} disable={!released} />
                </Grid>
                <Grid xs={4} item container justifyContent="flex-end">
                  <Box sx={{ pr: 4 }}>
                    {!isStudentView ? (
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <IconButton
                          icon={LinkIcon}
                          tooltip={
                            TEXT.components.course_schedule
                              .course_schedule_problem_set_row.copy_link_tooltip
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard
                              .writeText(
                                `${window.location.origin}/problem-set/${courseConfig.problem_set_id}/submit/${courseConfig.group_id}`
                              )
                              .then(() => {
                                // Successful copy
                                dispatch(
                                  showToast({
                                    message:
                                      TEXT.components.course_schedule
                                        .course_schedule_problem_set_row
                                        .copy_link_success,
                                    severity: "success",
                                    autoHideDuration: 5 * 1000,
                                  })
                                );
                              })
                              .catch((e) => {
                                // Failed copy
                                console.error("Failed to copy code: ", e);
                                showToast({
                                  message:
                                    TEXT.components.course_schedule
                                      .course_schedule_problem_set_row
                                      .copy_link_fail,
                                });
                              });
                          }}
                        />
                        <IconButton
                          icon={StudentIcon}
                          tooltip={
                            TEXT.components.course_schedule
                              .course_schedule_problem_set_row
                              .view_as_student_tooltip
                          }
                          onClick={(e) => {
                            dispatch(
                              setStudentPreviewMode({ modeState: true })
                            );
                            setTimeout(() => {
                              navigate(
                                makePath(`/problem-set/${courseConfig.problem_set_id}/submit/${courseConfig.group_id}`, searchParams)
                              );
                            }, 10);
                          }}
                        />
                        <ScheduleOptionsMenu
                          courseConfig={courseConfig}
                          handleRemove={handleRemove}
                          handleMove={handleMove}
                        />
                      </Stack>
                    ) : (
                      <ProblemSetGrade
                        problemSetId={problemSetRef.current.id}
                        courseConfig={courseConfig}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Tooltip>
      );
    }
  }, [
    courseConfig,
    handleMove,
    handleRemove,
    isStudentView,
    loading,
    mouseOver,
    navigate,
  ]);
};

const CourseSchedule = ({
  courseId,
  showAddProblemSetButtonOnEmpty = false,
  handleRemove = (courseConfig, completeCallback) => {},
  handleMove = (courseConfigs, placement, courseConfig, completeCallback) => {},
  setCourseScheduleForceUpdateCallback = (callback) => {},
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const courseConfigsRef = useRef([]);
  const loadingLockRef = useRef(false);
  const [updateToken, forceUpdate] = useReducer((x) => x + 1, 0);
  const isStudentView = useStudentView();

  // Cache bust so the refresh gets the latest changes
  const bustCache = useCallback(() => {
    return getAccessTokenSilently().then((token) => {
      getCourseConfig({
        params: {
          group_id: courseId,
        },
        authToken: token,
        dispatch: dispatch,
        cacheConfig: { override: true },
      });
    });
  }, [courseId, dispatch, getAccessTokenSilently]);

  setCourseScheduleForceUpdateCallback(() => {
    bustCache().then(() => forceUpdate());
  });

  useEffect(() => {
    // Skip if the configs are already loaded
    if (loadingLockRef.current) return;
    loadingLockRef.current = true;

    setLoading(true);
    getAccessTokenSilently()
      .then((token) => {
        return getCourseConfig({
          params: {
            group_id: courseId,
          },
          authToken: token,
          dispatch: dispatch,
        });
      })
      .then((configs) => {
        courseConfigsRef.current = configs;
      })
      .finally(() => {
        setLoading(false);
        //Unlock loading lock
        setTimeout(() => (loadingLockRef.current = false), 0);
      });
  }, [courseId, dispatch, getAccessTokenSilently, updateToken]);

  return useMemo(() => {
    if (loading) {
      return (
        <Fade in={true} timeout={300}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{
              border: "solid",
              borderColor: GT_COLORS.primaryLightBlue,
              borderRadius: "5px",
              borderWidth: "1px",
              width: "90%",
              p: 2,
            }}
            divider={<Divider orientation="horizontal" flexItem />}
          >
            <ContentLoader
              style={{ width: "100%" }}
              {...CONTENT_LOADER_DEFAULT_PROPS}
            >
              <rect x="0" y="0" rx="3" ry="3" width="100%" height="50" />

              <rect x="0" y="90" rx="3" ry="3" width="100%" height="50" />

              <rect x="0" y="180" rx="3" ry="3" width="100%" height="50" />

              <rect x="0" y="270" rx="3" ry="3" width="100%" height="50" />
            </ContentLoader>
          </Stack>
        </Fade>
      );
    } else if (!courseConfigsRef.current.length) {
      return (
        <NoProblemSets
          courseId={showAddProblemSetButtonOnEmpty ? courseId : null}
        />
      );
    } else {
      const sortedConfigs = sortCourseConfigs(courseConfigsRef.current);
      return (
        <Fade in={true} timeout={300}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
            sx={{
              border: "solid",
              borderColor: GT_COLORS.primaryLightBlue,
              borderRadius: "5px",
              borderWidth: "1px",
              width: "90%",
              pt: 3,
              pb: 3,
              pr: 1,
              pl: 1,
            }}
            divider={<Divider orientation="horizontal" flexItem />}
          >
            {!isStudentView && (
              <Box sx={{ mb: 2, mr: 1, ml: 1 }}>
                <AddProblemSetButton courseId={courseId} />
              </Box>
            )}
            {sortedConfigs.map((courseConfig) => {
              return (
                <Box
                  sx={{ m: 0.25 }}
                  key={
                    "course-schedule-row-" +
                    courseConfig.problem_set_id +
                    "-" +
                    courseConfig.group_id
                  }
                >
                  <CourseScheduleProblemSetRow
                    courseConfig={courseConfig}
                    handleRemove={(courseConfig, completeCallback) => {
                      handleRemove(courseConfig, () => {
                        completeCallback();
                        // Cache bust so the refresh above gets the latest changes
                        bustCache().then(() => forceUpdate());
                      });
                    }}
                    handleMove={(placement, courseConfig, completeCallback) =>
                      handleMove(
                        [...sortedConfigs],
                        placement,
                        courseConfig,
                        completeCallback
                      )
                    }
                  />
                </Box>
              );
            })}
          </Stack>
        </Fade>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMove, handleRemove, loading, updateToken, isStudentView]);
};

export default CourseSchedule;

const { default: endent } = require("endent");

export const formulaDocs = endent`
    A Grade Formula is a script, written in either Python or JavaScript, that calculates a student's
    overall problem set grade. Once the evaluation of the submitted code is complete, this script is
    executed. The script must contain a function "calculate" with the following signature
    \`calculate(scores, due_date, problem_ids)\`. The function should return a floating-point value between 0 and 1,
    representing the student's grade.

    **NOTE:** Modifying the formula will NOT retroactively adjust students' grades.

    ## Function arguments

    The \`calculate()\` function will receive the follow arguments:

    - \`due_date\` Optional(integer):  A POSIX timestamp (The number of seconds since January 1st, 1970 at UTC)
    indicating the assignment's due date. This may be null if the course configuration does not have a set due date.

    - \`problem_ids\` List/Array[str]: A set of strings that represent all the problem IDs found in the problem set.

    - \`scores\` List/Array[Dict/Object]: This is a sequence of results for each submission for this problem set made by the
    student. The array has the following format:

    ~~~json
    [
       {
            problem_id: string,
            submitted_at: integer,
            errors: integer,
            passed: integer,
            failed: integer,
            total: integer,
            results: Array[float],
        },
        ...
    ]
    ~~~

    **Score Properties**

    - \`problem_id\`: The unique ID of the problem.
    - \`submitted_at\`: A POSIX timestamp indicating when the submission was made.
    - \`errors\`: The number of tests that encountered an issue before completion. For instance, if the code fails to build, it would be counted as an error.
    - \`passed\`: The number of tests that passed. For a Programmatic Test, all sub-unit tests must pass in order for it to be considered a successful pass.
    - \`failed\`: The number of tests that did not pass.
    - \`total\` : The total number of tests.
    - \`results\` : A sequence of values ranging from 0 to 1, each value represents the individual results for each test in the problem. CLI Tests yield either 0 or 1. Whereas Programmatic Tests might produce a floating point value, depending on the count of passed/failed sub-unit tests.
    `;

export const defaultFormula = endent`
    from typing import Dict, List, Union
    from statistics import mean

    def calculate(scores, due_date, problem_ids):

        # Initialized with all 0's to account for un-submitted problems
        problems_score_map: Dict[str, Union[float, int]] = {
            problem_id: 0 for problem_id in problem_ids
        }

        for submission in scores:
            # Skip submissions without results
            if not submission["results"]:
                continue

            # Decide if the submission is on time
            on_time = not due_date or submission["submitted_at"] <= due_date

            # Calc the average test scores
            avg_test_score = mean(submission["results"])

            # Penalize late submission
            if not on_time:
                # Convert seconds to days
                days_diff = (submission["submitted_at"] - due_date) / (60 * 60 * 24)
                penalty = (days_diff ** 2)/100
                avg_test_score -= penalty

            pid = submission["problem_id"]

            # Add only the top scores for each problem
            problems_score_map[pid] = max(avg_test_score, problems_score_map.get(pid, 0))

        # Calculate the mean of the top problems scores
        if problems_score_map:
            return mean([score for score in problems_score_map.values()])
        else:
            # Catch situation when there is no graded submissions
            return 0
    `;

export const defaultFormulaDescription = endent`
Let's break down how your grade for this assignment is calculated 🧐:

1) For each problem you solve, I review all your submissions and select the one with the highest score.
2) If you submit a solution after the due date, there's a penalty based on the square of the number of days you're late $(\\text{days}^2)$. For instance, if you're 2 days late, 4 points will be deducted from your score for that problem $(2^2=4)$.
3) No worries! If you submit on or before the due date, there's no penalty.
4) In the end, your overall grade is the average of your best scores (whether on-time or late) for each problem.

I hope this clarifies things! If you have any questions, don't hesitate to ask me in class.

Happy Coding 🤓!
`;

export const defaultFormulaForStudent = endent`
Your grade for this assignment will be determined by taking the average of your
highest scores for each problem. If you submit your work on time, only those scores
will be considered. However, if there's no deadline for the assignment, your highest
scores from all submissions will be used to calculate your average.

Happy Coding 🤓!
`;

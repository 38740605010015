import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { GT_COLORS, MAX_WIDTH } from "../styles/Styles";
import loadable from "@loadable/component";
import { useMemo } from "react";
import { Helmet } from "react-helmet";

const Navbar = loadable(() => import("../components/Navbar"));
const Space = loadable(() => import("../components/Space"));

const TEXT = require("../text.json");

const Page = ({
  children,
  tabs = undefined,
  tools = [],
  enableBackButton = false,
  backButtonTitle = TEXT.components.back_button.aria_label,
  backButtonTooltip = TEXT.components.back_button.aria_label,
  enableNavBar = true,
  addBottomSpace = true,
  backButtonCallback = () => {},
  pageTitle = "",
}) => {
  const baseMeta = useMemo(() => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle || TEXT.meta.title}</title>
        <meta name="keywords" content={TEXT.meta.keywords} />
        <meta name="description" content={TEXT.meta.description} />
        <meta name="author" content={TEXT.meta.author} />
      </Helmet>
    );
  }, []);

  return (
    <Box
      id="page-root-box"
      sx={{ width: "100vw", maxWidth: MAX_WIDTH }}
    >
      {baseMeta}
      {enableNavBar && (
        <Navbar
          tabs={tabs}
          tools={tools}
          enableBackButton={enableBackButton}
          backButtonTitle={backButtonTitle}
          backButtonCallback={backButtonCallback}
          backButtonTooltip={backButtonTooltip}
        />
      )}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        sx={{
          marginTop: "90px",
          marginRight: 5,
          marginLeft: 5,
          marginBottom: 3,
          maxWidth: MAX_WIDTH,
        }}
        display="flex"
      >
        {children}
        {addBottomSpace && <Space h={"50vh"} />}
      </Stack>
    </Box>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  tabs: PropTypes.element,
  tools: PropTypes.array,
};

export default Page;

import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import { useSearchParams } from "react-router-dom";
import CoursesSoloPage from "./CourseSolo";

const InstructorMainPage = loadable(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ "./InstructorMain")
);
const CoursesGalleryPage = loadable(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ "./CoursesGallery")
);

const Main = () => {
  // @ts-ignore
  const isTeacher = useSelector((state) => state.user.userInfo.isTeacher);
  const studentPreviewMode = useSelector(
    // @ts-ignore
    (state) => state.appState.studentPreviewMode
  );
  // @ts-ignore
  const isStudentView = !isTeacher || (studentPreviewMode && isTeacher);

  let [searchParams, setSearchParams] = useSearchParams();

  const homeCourse = searchParams.get("homeCourse");
  if (homeCourse) {
    return <CoursesSoloPage courseId={homeCourse} />;
  } else if (!isStudentView) {
    return <InstructorMainPage />;
  } else {
    return <CoursesGalleryPage />;
  }
};

export default Main;

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrivacyMode, setStudentPreviewMode } from "./slices/appState";

/**
 * Generates a random string of the specified length.
 * @param {number} length - The length of the random string to generate.
 * @returns {string} - The randomly generated string.
 */
export function randomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;

  // Generate characters until the desired length is reached
  while (counter < length) {
    // Append a random character from the characters string to the result
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
}

/**
 * Retrieves the text that matches the given language code from an array of text objects.
 * If no language code is specified, it returns the first primary text item with
 * primary equal to true. If there is no primary text and no text matching the
 * language code, it returns the text with an English language code. If the
 * array is empty, it returns an empty string.
 *
 * @param {Array} textArray - The array of text objects.
 * @param {string} languageCode - The language code to match.
 * @returns {string} - The matching text or fallback text based on the specified
 * conditions.
 */
export function getTextByLanguage(textArray, languageCode = null) {
  // Check if the array is empty
  if (textArray.length === 0) {
    return "";
  }

  let primaryText = null; // Stores the primary text
  let englishText = null; // Stores the English text
  let defaultText = null; // Stores the default text

  // Iterate through the array of text objects
  for (let i = 0; i < textArray.length; i++) {
    const textObj = textArray[i];

    // Check if the text object is marked as primary
    if (textObj.primary) {
      primaryText = textObj.text;

      // Check if the language code equals the specified value
      if (languageCode && textObj.language_code === languageCode) {
        return textObj.text; // Return the matching text
      }
    }

    // Check if the language code starts with "en" and English text is not yet
    // set
    if (textObj.language_code.startsWith("en") && englishText === null) {
      englishText = textObj.text;
    }

    // Check if the default text is not set yet
    if (defaultText === null) {
      defaultText = textObj.text;
    }
  }

  // If a language code is specified, return the English text or default text if
  // available
  if (languageCode) {
    return englishText || defaultText;
  }

  // Return the primary text if available, or the default text
  return primaryText || defaultText;
}

/**
 * Transforms an array of submissions into an object where each problem id maps
 * to a list of problem results objects that share the same problem id. The 'id', 'code',
 * 'problem_set_id', 'group_id' and 'created_at' field from the submission is ,
 * added to each, problem object. The 'id' is renamed to 'submission_id'. The ,
 * problem list for, each problem id is sorted in descending order by the ,
 * 'created_at' field.
 *
 * @param {Array} submissions - The array of submission objects to be
 * transformed. Each submission object should have a 'created_at' field
 * indicating when it was created, a 'problems' field which is an array of
 * problem objects, and each problem object should have a 'problem_id' field and
 * a 'result' field.
 *
 * @return {Object} - Returns an object where each key is a problem id, and the
 * value is an array of problem objects that share the same id. Each problem
 * object includes a 'created_at' field indicating when the corresponding
 * submission was created.
 *
 * @example
 * const submissions = [
 *   {
 *     "id": "abc123",
 *     "created_at": "2023-06-20T10:00:00Z",
 *     "problems": [
 *       {
 *         "problem_id": "60d0f14d824f4f23a1d7d202",
 *         "result": "1",
 *       },
 *     ]
 *   }
 * ];
 *
 * transformSubmissionsToResultsDisplay(submissions);
 * // returns
 * // {
 * //   "60d0f14d824f4f23a1d7d202": [
 * //     {
 * //       "problem_id": "60d0f14d824f4f23a1d7d202",
 * //       "result": "1",
 * //       "created_at": "2023-06-20T10:00:00Z",
 * //       "submission_id": "abc123",
 * //       "code" : "print("Hello World")",
 * //       "problem_set_id" : "6129c72a822f4e23b26b7c02"
 * //       "group_id" : "crs-12345"
 * //     },
 * //   ]
 * // }
 */
export function transformSubmissionsToResultsDisplay(submissions) {
  let resultMap = {};
  let dupResultMap = {};

  submissions.forEach((submission) => {
    submission.problems.forEach((problem) => {
      if (!resultMap[problem.problem_id]) {
        resultMap[problem.problem_id] = [];
      }

      const existingProblems = resultMap[problem.problem_id] || [];
      const existingSubmissionIds = existingProblems.map(
        (prob) => prob.submission_id
      );

      if (!existingSubmissionIds.includes(submission.id)) {
        const newVer = JSON.parse(JSON.stringify(problem));
        newVer["status"] = submission.status;
        newVer["created_at"] = submission.created_at;
        newVer["group_id"] = submission.group_id;
        newVer["submission_id"] = submission.id;
        newVer["code"] = problem.code;
        newVer["problem_set_id"] = problem.problem_set_id;
        newVer["result"] = problem.results.every(
          (result) => result.payload?.result === true
        );
        newVer["problem_result_id"] = problem.problem_result_id;
        newVer["previous_result_id"] = problem.previous_result_id;

        resultMap[problem.problem_id].push(newVer);
        dupResultMap[problem.problem_result_id] = newVer;
      }
    });
  });

  // Sort the problems by date in descending order.
  for (let problem_id in resultMap) {
    resultMap[problem_id].sort((a, b) => {
      // @ts-ignore
      return new Date(b.created_at) - new Date(a.created_at);
    });
  }

  // Connect duplicates
  for (let problem_id in resultMap) {
    resultMap[problem_id].forEach((problemResult) => {
      if (problemResult.previous_result_id) {
        problemResult.previous_result =
          dupResultMap[problemResult.previous_result_id];
      }
    });
  }

  return resultMap;
}

export const updateProblemResults = (problemResultsArray, submission) => {
  const newProblemResultsArray = JSON.parse(
    JSON.stringify(problemResultsArray)
  );

  submission.problems.forEach((problem) => {
    const problemResult = newProblemResultsArray.find(
      (sub) => sub.submission_id === submission.id
    );

    if (problemResult) {
      for (const problemKey in problem) {
        problemResult[problemKey] = problem[problemKey];
      }

      problemResult.status = submission.status;
      problemResult.result = problem.results.every(
        (result) => result.payload?.result === true
      );
      problemResult.problem_result_id = problem.problem_result_id;
      problemResult.previous_result_id = problem.previous_result_id;
    }
  });

  return newProblemResultsArray;
};

/**
 * Formats a date/time value into a easy to understand humanized relative time
 * representation or a specific date format.
 *
 * @param {string} dateTime - The date/time value to format. It must be a ISO
 * 8601 formatted string.
 * @returns {string} - The formatted relative time representation or specific
 * date format.
 */
export const formateRelativeDate = (dateTime) => {
  const humanizeDuration = require("humanize-duration");
  const Sugar = require("sugar");
  const submittedTimestamp = Date.parse(dateTime);
  const duration = Date.now() - submittedTimestamp;
  const dateActual = new Date(submittedTimestamp);

  if (duration / 1000 < 60) {
    // Less than 1 second
    return "A moment ago";
  } else if (Sugar.Date.hoursAgo(dateActual) < 1) {
    // Less than a hour
    return humanizeDuration(duration, { units: ["m"], round: true }) + " ago";
  } else if (Sugar.Date.hoursAgo(dateActual) < 24) {
    // less than a day
    return humanizeDuration(duration, { units: ["h"], round: true }) + " ago";
  } else if (Sugar.Date.monthsAgo(dateActual) < 6) {
    return Sugar.Date.format(dateActual, "{Dow}, {Mon} {do}, {h}:{mm} {tt}");
  } else {
    return Sugar.Date.medium(dateActual);
  }
};

export function isEmpty(obj) {
  return obj && Object.keys(obj).length === 0;
}

export const getHighestValueLanguage = (statistics, languages) => {
  // Convert the statistics keys to lowercase for matching
  const lowercaseStats = Object.entries(statistics).reduce(
    (acc, [lang, value]) => {
      acc[lang.toLowerCase()] = value;
      return acc;
    },
    {}
  );

  // Filter the statistics to get only the relevant languages
  const relevantStats = languages.map((lang) => [
    lang,
    lowercaseStats[lang] || 0,
  ]);

  // Find the highest value among the relevant languages
  const highestValue = Math.max(...relevantStats.map(([, value]) => value));

  // Return the language with the highest value
  const [highestLang] = relevantStats.find(
    ([, value]) => value === highestValue
  ) || [null];

  return highestLang;
};

/**
 * Truncates a string to a specified maximum length if truncation occurs.
 *
 * @param {string} str - The string to truncate.
 * @param {number} maxLength - The maximum length to which the string should be truncated.
 * @returns {string} The truncated string, or the original string if its length is less than or equal to maxLength.
 *
 * @example
 * const original = "This is a long string";
 * const truncated = truncateString(original, 10);
 * console.log(truncated); // "This is a"
 */

export const truncateString = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength);
};

/**
 * Waits for a React ref to become null.
 *
 * This function checks if the provided ref's current value is null.
 * If it's not null, the function will check again every 100 milliseconds
 * until the ref becomes null. Once the ref is null, the promise resolves.
 *
 * @param {React.RefObject} ref - The React ref object to monitor.
 * @returns {Promise<void>} A promise that resolves once the ref's current value is null.
 *
 * @example
 * const ref = useRef(someValue);
 * waitForRefToBeNull(ref).then(() => {
 *   console.log('Ref is now null!');
 * });
 */
export const waitForRefToBeNull = (ref) => {
  return new Promise((resolve) => {
    if (ref.current === null || ref.current === undefined) {
      resolve();
    } else {
      setTimeout(() => waitForRefToBeNull(ref).then(resolve), 100); // check every 100ms
    }
  });
};

/**
 * Determines if the application is in student-preview mode.
 *
 * The function checks the current URL's query parameters to see
 * if a 'preview' parameter exists. If it does, the application is
 * considered to be in student-preview mode.
 *
 * @returns {boolean} - Returns true if in student-preview mode, false
 * otherwise.
 *
 * @example
 * if (isPreviewMode()) {
 *   console.log('The application is in student-preview mode.');
 * } else {
 *   console.log('The application is not in student-preview mode.');
 * }
 */
export function isPreviewMode() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has("preview");
}

/**
 * Modifies the current URL's query parameters based on given instructions.
 *
 * The function can add or remove query parameters based on the provided
 * 'add' and 'remove' arguments. It returns the modified query parameters as a
 * string.
 *
 * @param {Object} add - An object containing key-value pairs to be added to the
 * query params.
 * @param {Array<string>} remove - An array of query parameter keys to be
 * removed.
 *
 * @returns {string} - Returns modified query parameters starting with "?"
 *                     if there's at least one, otherwise returns an empty
 *                     string.
 *
 * @example
 * let currentURL = "https://example.com?color=red&size=large";
 * let modifiedQuery = makeQueryParams({ shape: 'circle' }, ['size']);
 * // modifiedQuery => "?color=red&shape=circle"
 */
export function makeQueryParams(add, remove) {
  const urlParams = new URLSearchParams(window.location.search);

  // Add query parameters
  for (const [key, value] of Object.entries(add)) {
    urlParams.set(key, value);
  }

  // Remove query parameters
  for (const key of remove) {
    urlParams.delete(key);
  }

  return urlParams.toString() ? "?" + urlParams.toString() : "";
}

export const useStudentView = () => {
  // @ts-ignore
  const isTeacher = useSelector((state) => state.user.userInfo.isTeacher);
  const studentPreviewMode = useSelector(
    // @ts-ignore
    (state) => state.appState.studentPreviewMode
  );
  // @ts-ignore
  return !isTeacher || (studentPreviewMode && isTeacher);
};

export const isAssignmentReleased = (isStudentView, courseConfig) => {
  return (
    (isStudentView &&
      courseConfig.release &&
      new Date(courseConfig.release).getTime() < new Date().getTime()) ||
    !isStudentView ||
    !courseConfig.release
  );
};

export const isAssignmentClosed = (isStudentView, courseConfig) => {
  console.log(
    "isAssignmentClosed",
    isStudentView,
    Boolean(courseConfig?.until),
    new Date(courseConfig.until).getTime() < new Date().getTime()
  );
  return (
    isStudentView &&
    Boolean(courseConfig?.until) &&
    new Date(courseConfig?.until).getTime() < new Date().getTime()
  );
};

/**
 * Custom React hook for managing iframe communications and history updates.
 *
 * @param {object} params Configuration object for the hook.
 * @param {Function} [params.onMessage] Callback function that gets executed when a message is received from the iframe. It takes an object with 'type' and 'value' properties.
 * @param {boolean} [params.listen] Flag indicating whether to listen for messages from the iframe.
 * @param {string} [params.serviceId] The service identifier of this application.
 * @param {Array<String>} [params.listeningServices] An array of service identifiers to listen for
 * @returns {object} Returns an object containing the 'postMessage' function that can be used to send messages to the iframe.
 *
 */
export function useIframe({
  onMessage = ({ type, value }) => {},
  listen = true,
  serviceId = "auto.graderthan.com",
  listeningServices = ["portal.graderthan.com"],
} = {}) {
  const dispatch = useDispatch();

  const sendMessage = useCallback((message) => {
    console.debug(
      `[<=] auto.graderthan.com/iframe sent "${message.type}" message to parent`
    );

    window.parent.postMessage({ service: serviceId, ...message }, "*");
  }, []);

  const sendCheckin = useCallback(() => {
    sendMessage({ type: "checkin", value: "" });
  }, [sendMessage]);

  const sendBack = useCallback(() => {
    sendMessage({ type: "back", value: "" });
  }, [sendMessage]);

  // Adding message listener
  useEffect(() => {
    const handleMessage = (event) => {
      if (listen && listeningServices.includes(event.data?.service)) {
        const eventType = event.data?.type;

        console.debug(
          "[=>] auto.graderthan.com/iframe received message:",
          event.data
        );

        onMessage({ type: eventType, value: event.data.value });

        switch (eventType) {
          case "privacyMode":
            dispatch(setPrivacyMode({ modeState: event.data.value }));
            break;

          case "studentPreview":
            dispatch(setStudentPreviewMode({ modeState: event.data.value }));
            break;
          default:
        }
      }
    };

    window.addEventListener("message", handleMessage);
    // Clean up the event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return { sendMessage, sendCheckin, sendBack };
}

/**
 * Constructs a URL or path string by combining a base path with additional query parameters.
 * If the base path already includes query parameters, the new parameters are appended or existing ones are updated.
 *
 * @param {string} path The base URL or path, which may already include query parameters.
 * @param {URLSearchParams} newParams A `URLSearchParams` object containing the new query parameters to be added or updated.
 * @returns {string} The combined URL or path string with updated query parameters.
 *
 * @example
 * // Append new query parameters to a path with existing parameters
 * console.log(makeUrl("/path/?param1=value1", new URLSearchParams('param2=value2')));
 * // Output: "/path/?param1=value1&param2=value2"
 *
 * @example
 * // Append query parameters to a path without existing parameters
 * console.log(makeUrl("/path/", new URLSearchParams('param2=value2')));
 * // Output: "/path/?param2=value2"
 */
export function makePath(path, newParams) {
  // Extract the path and existing query parameters
  const [basePath, queryString] = path.split("?");
  const existingParams = new URLSearchParams(queryString);

  // Append new parameters
  for (const [key, value] of newParams) {
    existingParams.set(key, value);
  }

  // Reconstruct the URL/path with updated query parameters
  const updatedQueryString = existingParams.toString();
  return `${basePath}${updatedQueryString ? `?${updatedQueryString}` : ""}`;
}

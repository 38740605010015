import { debounce } from "throttle-debounce";
import { getSchema } from "./models";

/**
 * Sets a nested value within an object based on a given string key.
 * If any intermediate keys do not exist, it throws an error.
 *
 * @param {Object} obj - The target object in which to set the value.
 * @param {string} keyString - A dot-separated string representing the nested key.
 *        For example, "key1.key2.key3" will target obj[key1][key2][key3].
 * @param {*} value - The value to set at the specified nested key.
 *
 * @throws {Error} If an intermediate key does not exist in the object.
 *
 * @example
 * const myObj = {
 *   key1: {
 *     key2: {}
 *   }
 * };
 *
 * setNestedValue(myObj, 'key1.key2.key3', 'desiredValue');
 * console.log(myObj.key1.key2.key3);  // Outputs: 'desiredValue'
 */
function setNestedValue(obj, keyString, value) {
  const keys = keyString.split(".");
  let current = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    // If we're at the last key in the string, set the value
    if (i === keys.length - 1) {
      current[key] = value;
    } else {
      // If the next key in the sequence doesn't exist, throw an error
      if (!(key in current)) {
        throw new Error(`Intermediate key ${key} doesn't exist.`);
      }
      current = current[key];
    }
  }
}

/**
 * Retrieves a nested value from an object based on a given string key.
 * If any intermediate keys do not map to an object, it throws an error.
 *
 * @param {Object} obj - The target object from which to retrieve the value.
 * @param {string} keyString - A dot-separated string representing the nested key.
 *        For example, "key1.key2.key3" will target obj[key1][key2][key3].
 *
 * @returns {*} The value at the specified nested key, or undefined if the key path doesn't exist.
 *
 * @throws {Error} If an intermediate key doesn't map to an object.
 *
 * @example
 * const myObj = {
 *   key1: {
 *     key2: {
 *       key3: 'desiredValue'
 *     }
 *   }
 * };
 *
 * console.log(getNestedValue(myObj, 'key1.key2.key3'));  // Outputs: 'desiredValue'
 */
function getNestedValue(obj, keyString) {
  const keys = keyString.split(".");

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (obj === undefined || obj === null) {
      return undefined; // Key path doesn't exist
    }

    // If we're not at the last key and the current key doesn't map to an object, throw an error
    if (i !== keys.length - 1 && !(key in obj)) {
      throw new Error(`Intermediate key ${key} doesn't map to a value.`);
    }

    obj = obj[key];
  }

  return obj;
}

/**
 * Moves an item in an array by a specified number of positions.
 *
 * @param {Array} arr - The array containing the item to move.
 * @param {number} index - The current index of the item in the array.
 * @param {number} n - The number of positions to move the item. A positive value moves the item to the right, and a negative value moves it to the left.
 *
 * @returns {Array} The modified array with the item moved by n positions.
 *
 * @throws {Error} If the move operation results in an out-of-bounds index.
 *
 * @example
 * const array = [1, 2, 3, 4, 5];
 *
 * console.log(moveItemByN(array, 2, 1));  // Expected: [1, 2, 4, 3, 5]
 * console.log(moveItemByN(array, 3, -2)); // Expected: [1, 3, 2, 4, 5]
 */
function moveItemByN(arr, index, n) {
  if (
    index + n < 0 ||
    index + n >= arr.length ||
    index < 0 ||
    index >= arr.length
  ) {
    throw new Error("Invalid move operation");
  }

  const [item] = arr.splice(index, 1); // Remove the item from the array
  arr.splice(index + n, 0, item); // Insert the item n positions away from its original position

  return arr;
}

export class ValidationError extends Error {
  constructor(errors) {
    super(errors.join("\n- ")); // Pass the message to the Error constructor
    this.errors = errors;

    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name;

    // This line maintains a correct stack trace
    if ("captureStackTrace" in Error)
      Error.captureStackTrace(this, this.constructor);
  }
}

/**
 * GeneralSaveManager class for handling save operations related to a general object.
 */
export class GeneralSaveManager {
  /**
   * Constructor for the GeneralSaveManager class.
   *
   * @param {Object} options - The function options.
   * @param {string} options.type - The type of the object. Can be 'Problem',
   * 'ProblemSet', 'GradeFormula', 'CourseConfiguration', or 'Submission'.
   * @param {Object} options.object - The current state of the object.
   * @param {(...args: any[]) => any} options.saveCallback - The callback
   * function to be called to save changes to the object's properties.
   * @param {Function} [options.getLatestCallback] - An optional callback
   * function to fetch the latest version of the object.
   * @param {Object} options.schema - The JSON schema to use to validate the
   * information.
   *
   * @throws {Error} Throws an error if the provided type is not one of the allowed types.
   */
  constructor({
    type,
    object,
    saveCallback,
    getLatestCallback,
    schema = null,
  }) {
    const allowedTypes = [
      "Problem",
      "ProblemSet",
      "GradeFormula",
      "CourseConfiguration",
      "Submission",
    ];
    if (!allowedTypes.includes(type)) {
      throw new Error(
        `Invalid type '${type}'. Must be one of ${allowedTypes.join(", ")}.`
      );
    }

    this.type = type;
    this.object = object;
    this.mirrorObject = JSON.parse(JSON.stringify(object));
    this.saveCallback = (type, object, saveOptions, updater) => {
      navigator.locks.request(this.type + this.object.id, async (lock) => {
        await saveCallback(type, object, saveOptions, updater)
      })
    };
    this.delayedSaveCallback = debounce(700, this.saveCallback);
    this.getLatestCallback = getLatestCallback;
    this.schema = schema;
  }

  /**
   * Method to set a property on the object.
   *
   * @param {string} key - The property key.
   * @param {*} value - The new value for the property.
   */
  setProperty(key, value, saveOptions) {
    const valueActual = getNestedValue(this.object, key);
    if (valueActual !== value) {
      navigator.locks.request(this.type + this.object.id, async (lock) => {
        setNestedValue(
          this.object,
          key,
          value !== null && value !== undefined
            ? JSON.parse(JSON.stringify(value))
            : null
        );
      });
      this.save(saveOptions);
    }
  }

  getProperty(key, defaultValue) {
    try {
      const value = getNestedValue(this.object, key);

      // Return default value because value is undefined or null
      if (value === undefined || value === null) {
        return defaultValue;
      } else {
        // Return a copy of the value
        return JSON.parse(JSON.stringify(value));
      }
    } catch {
      return defaultValue;
    }
  }

  /**
   * Method to save the changes made to the object. This method first validates
   * the object, then calls the save callback with the type, object, and
   * saveOptions. If validation passes, the object is saved and the mirrorObject
   * is updated with a clone of the object. If validation fails, all changes to
   * the object are rolled back and the validation error is re-thrown.
   *
   * @param {Object} saveOptions - An optional argument that may contain various
   * options for the save operation.
   * @param {Boolean} saveOptions.immediateSave - True to force the save to
   * occur immediately. Otherwise it's debounced. Defaults to False.
   * @param {(success: Boolean) => any} saveOptions.saveCompleteCallback -
   * [Optional] a function called upon completion of the save. This is the best
   * callback to use to listen for save completion in both the immediate and
   * debounce situation.
   * @returns {any} - The result of the saveCallback() either a promise if it's
   * an immediate save. Otherwise a new, debounced function.
   * @throws {Error} - If validation fails.
   */
  save(saveOptions) {
    try {
      const objectUpdater = (newObject) => {
        if (newObject) {
          this.updateMirror();
          this.object = JSON.parse(JSON.stringify(newObject));
        }
      };

      this.validate();

      const saveResult = saveOptions?.immediateSave
        ? this.saveCallback(
            this,
            saveOptions || {},
            objectUpdater
          )
        : this.delayedSaveCallback(
            this,
            saveOptions || {},
            objectUpdater
          );

      return saveResult;
    } catch (e) {
      this.rollback();
      throw e;
    }
  }

  /**
   * Ensures the underlying object is valid based on the schema. When this class
   * is extended override this function with your own custom validation logic
   *
   * @throws {ValidationError} - If the underlying object fails the validation check
   */
  validate() {}

  async update() {
    this.updateMirror();
    await navigator.locks.request(this.type + this.object.id, async (lock) => {
      try {
        this.object = await this.getLatestCallback();
        this.mirrorObject = JSON.parse(JSON.stringify(this.object));
      } catch (error) {
        console.error(
          `Failed to get the latest object. Reason: ${error.message}`
        );
        this.rollback();
        throw error;
      }
    });
  }

  updateMirror() {
    this.mirrorObject = JSON.parse(JSON.stringify(this.object));
  }

  rollback() {
    this.object = JSON.parse(JSON.stringify(this.mirrorObject));
  }

  // Custom equality method
  equals(otherManager) {
    if (!(otherManager instanceof GeneralSaveManager)) return false;
    if (otherManager.type !== this.type) return false;

    var hash = require("object-hash");
    return hash(this.object) === hash(otherManager.object);
  }
}

/**
 * ProblemSaveManager class for handling save operations related to a problem
 * set. It extends from GeneralSaveManager
 */
export class ProblemSetSaveManager extends GeneralSaveManager {
  /**
   * Constructor for the ProblemSetSaveManager class.
   *
   * @param {Object} options - The function options.
   * @param {Object} options.object - The problem set object.
   * @param {(...args: any[]) => any} options.saveCallback - The callback
   * function to be called on changes to the problem set's properties.
   * @param {Function} [options.getLatestCallback] - An optional callback
   * function to fetch the latest version of the problem set.
   * @param {Object} options.schema - The JSON schema to use to validate the
   * information.
   */
  constructor({ object, saveCallback, getLatestCallback, schema = null }) {
    super({
      type: "ProblemSet",
      object: object,
      saveCallback: saveCallback,
      getLatestCallback: getLatestCallback,
      schema: schema,
    });
    this.loaded_problems = {};
    this.problemSetSchema = getSchema({
      objectName: "problemset",
      jsonSchema: this.schema,
    });
  }

  validate() {
    const valid = this.problemSetSchema.validate(this.object);
    if (!valid) {
      console.debug(
        this.type + " Validation Errors:",
        this.problemSetSchema.validate.errors
          .map((error) => error.instancePath + ": [" + error.message + "]")
          .join(", ")
      );
      throw new ValidationError(
        this.problemSetSchema.validate.errors.map((error) => error.message)
      );
    }
  }

  /**
   * Method to retrieve an existing ProblemSaveManager associated with a problem
   * ID. Throws an error if no problem or ProblemSaveManager is found with the
   * provided ID.
   *
   * @param {string} problemId - The ID of the problem.
   * @returns {ProblemSaveManager} - A ProblemSaveManager instance for the
   * specified problem.
   * @throws {Error} - If no problem or ProblemSaveManager is found with the
   * provided ID.
   */
  getProblemSaveManager(problemId) {
    if (problemId in this.loaded_problems) {
      return this.loaded_problems[problemId];
    }

    if (!this.object.problems.includes(problemId)) {
      throw new Error(`No problem found with id ${problemId}`);
    }

    if (!(problemId in this.loaded_problems)) {
      throw new Error(`No problem save manager found with id ${problemId}`);
    }

    return this.loaded_problems[problemId];
  }

  /**
   * Initializes ProblemSaveManager instances for each problem in the problem
   * set. This method is expected to be called once after the problem set is
   * created or loaded.
   *
   * @param {Object} options - The function options.
   * @param {Function} options.problemGetter - A function that takes a problemId and
   * returns the corresponding problem.
   * @param {Function} options.getLatestCallback - A callback function to fetch the latest version of a problem given its ID.
   *
   * @throws {Error} - If a falsy value is returned by the problemGetter for any
   * problem ID, an error will be thrown indicating the problematic problem ID.
   */
  initProblemSaveManagers({ problemGetter, getLatestCallback }) {
    this.object.problems.forEach((problemId) => {
      const orgProblem = problemGetter(problemId);
      const problem = JSON.parse(JSON.stringify(orgProblem));

      if (!problem) {
        throw new Error(
          `Received "${problem}" for the problem ID ${problemId}`
        );
      }

      const problemSaveManager = new ProblemSaveManager({
        object: problem,
        saveCallback: this.saveCallback,
        getLatestCallback: () => getLatestCallback(problemId),
        schema: this.schema,
      });

      this.loaded_problems[problemId] = problemSaveManager;
    });
    console.log("FINISHED LOADING PROBLEMS");
  }

  /**
   * Checks if ProblemSaveManager instances have been initialized for all
   * problems in the problem set. Returns true if ProblemSaveManager instances
   * exist for all problems, false otherwise.
   *
   * @returns {boolean} - True if ProblemSaveManager instances exist for all
   * problems, false otherwise.
   */
  isProblemsInit() {
    const loadedProblemKeys = Object.keys(this.loaded_problems).map(String);
    const isValid =
      loadedProblemKeys.every((key) => this.object.problems.includes(key)) &&
      loadedProblemKeys.length === this.object.problems.length;

    return isValid;
  }

  /**
   * Method to remove a problem and its associated ProblemSaveManager from the
   * problem set.
   *
   * @param {string} problemId - The ID of the problem to remove.
   * @param {Object} saveOptions - Options passed to the save callback
   * @returns {any} - The result of the saveCallback()
   * @throws {Error} - If no problem is found with the provided ID.
   */
  removeProblem(problemId, saveOptions) {
    const problemIndex = this.object.problems.findIndex(
      (innerId) => innerId === problemId
    );

    if (problemIndex === -1) {
      throw new Error(`No problem found with id ${problemId}`);
    }

    // Remove the problem from the problem set
    this.object.problems.splice(problemIndex, 1);

    // Remove the associated ProblemSaveManager from loaded_problems, if it exists
    if (problemId in this.loaded_problems) {
      delete this.loaded_problems[problemId];
    }

    return this.save(saveOptions);
  }

  /**
   * Method to add a problem to the problem set, up to a specified limit.
   *
   * @param {string} problemId - The ID of the problem to add.
   * @param {Object} saveOptions - Options passed to the save callback
   * @returns {any} - The result of the saveCallback()
   * @throws {Error} - If the problem already exists in the problem set or if
   * the limit is exceeded.
   */
  addProblem(problemId, saveOptions) {
    // Check if the problem is already in the problem set
    if (this.object.problems.includes(problemId)) {
      throw new Error(
        `Problem with id ${problemId} already exists in the problem set.`
      );
    }

    // Add the problem to the problem set
    this.object.problems.push(problemId);

    // Save the updated problem set
    return this.save(saveOptions);
  }
}

/**
 * ProblemSaveManager class for handling save operations related to a problem.
 * It extends from GeneralSaveManager
 */
export class ProblemSaveManager extends GeneralSaveManager {
  /**
   * Constructor for the ProblemSaveManager class.
   *
   * @param {Object} options - The function options.
   * @param {Object} options.object - The problem object.
   * @param {(...args: any[]) => any} options.saveCallback - The callback
   * function to be called on changes to the problem's properties.
   * @param {Function} [options.getLatestCallback] - An optional callback
   * function to fetch the latest version of the object.
   * @param {Object} options.schema - The JSON schema to use to validate the
   * information.
   */
  constructor({ object, saveCallback, getLatestCallback, schema }) {
    super({
      type: "Problem",
      object: object,
      saveCallback: saveCallback,
      getLatestCallback: getLatestCallback,
      schema: schema,
    });

    this.problemSchema = getSchema({
      objectName: "problem",
      jsonSchema: this.schema,
    });
    this.unitSchema = getSchema({
      objectName: "unittest",
      jsonSchema: this.schema,
    });
    this.cliSchema = getSchema({
      objectName: "clitest",
      jsonSchema: this.schema,
    });
  }

  validate() {
    let errors = [];

    this.object.tests.forEach((test) => {
      if (test.test_type === "cli") {
        const valid = this.cliSchema.validate(test);
        if (!valid) {
          errors = errors.concat(
            this.cliSchema.validate.errors.map((error) => error.message)
          );
        }
      }

      if (test.test_type === "unit") {
        const valid = this.unitSchema.validate(test);
        if (!valid) {
          errors = errors.concat(
            this.unitSchema.validate.errors.map((error) => error.message)
          );
        }
      }
    });

    // Fail fast and show error messages for tests that failed
    if (errors.length) throw new ValidationError(errors);

    const valid = this.problemSchema.validate(this.object);

    if (!valid) {
      errors = errors.concat(
        this.problemSchema.validate.errors.map((error) => error.message)
      );
    }

    if (errors.length) throw new ValidationError(errors);
  }

  /**
   * Method to get a test by its ID.
   *
   * @param {string} test_id - The ID of the test to retrieve.
   * @returns {Object} - The test object.
   */
  getTest(test_id, noCopy = false) {
    let test = this.object.tests.find((test) => test.test_id === test_id);

    if (!test) {
      throw new Error(`No test found with id ${test_id}`);
    }

    return !noCopy ? JSON.parse(JSON.stringify(test)) : test;
  }

  /**
   * Updates a property of a test action
   *
   * @param {Object} options - The function options.
   * @param {String} options.testId - The ID of the test to update
   * @param {String} options.key - The name of the property within the test
   * to update
   * @param {any} [options.value] - The value to assign to the test
   * @param {Object} [options.saveOptions] - The options to pass to the save function
   */
  setTestProperty({ testId, key, value, saveOptions }) {
    let test = this.getTest(testId, true);

    if (key in test && test[key] !== value) {
      test[key] = JSON.parse(JSON.stringify(value));
      return this.save(saveOptions);
    } else {
      if (saveOptions?.saveCompleteCallback) {
        saveOptions.saveCompleteCallback(true);
      }
      console.debug(
        `Skipping test save because the value for ${key} was the same.`
      );
    }
  }

  /**
   * Retrieves a property of a test action
   *
   * @param {Object} options - The function options.
   * @param {String} options.testId - The ID of the test to update
   * @param {String} options.key - The name of the property within the test
   * to get
   * @param {Boolean} options.noCopy - True will return the original value. False
   * returns a copy of the value. Defaults to false.
   */
  getTestProperty({ testId, key, noCopy = false }) {
    let test = this.getTest(testId, true);
    const value = getNestedValue(test, key);
    return noCopy ? value : JSON.parse(JSON.stringify(value));
  }

  /**
   * Method to set a new test.
   *
   * @param {Object} newTest - The new test object.
   * @param {Object} saveOptions - Options passed to the save callback
   * @returns {any} - The result of the saveCallback() if a change occurs
   * otherwise undefined
   */
  setTest(newTest, saveOptions) {
    let index = this.object.tests.findIndex(
      (test) => test.test_id === newTest.test_id
    );

    if (index === -1) {
      throw new Error(`No test found with id ${newTest.test_id}`);
    }

    let oldTest = this.object.tests[index];

    // Check if the new test is different from the old test
    if (JSON.stringify(oldTest) !== JSON.stringify(newTest)) {
      this.object.tests[index] = JSON.parse(JSON.stringify(newTest));
      return this.save(saveOptions);
    } else {
      if (saveOptions?.saveCompleteCallback) {
        saveOptions.saveCompleteCallback(true);
      }
      console.debug(
        `Skipping save because no changes we found in test ${newTest.test_id}`
      );
    }
  }

  /**
   * Method to get a test action by its ID.
   *
   * @returns {Array} - An array of cli test action objects.
   */
  getAllTestActions({ testId }) {
    const test = this.getTest(testId);

    if (test.test_type !== "cli") {
      throw Error(
        `The test ${testId} must be a CLI test. It was a ${test.test_type}`
      );
    }

    return JSON.parse(JSON.stringify(test.script));
  }

  /**
   * Method to get a test action by its ID.
   *
   * @param {Object} options - The function options.
   * @param {String} options.actionId - The ID of the test action to retrieve
   * @param {Boolean} options.returnCopy - Return a deep copy of the test action
   * @returns {Object} - The action object.
   */
  getTestAction({ actionId, returnCopy = true }) {
    // Iterate over all tests
    for (let test of this.object.tests) {
      // Find the action with the given ID
      if (!("script" in test)) continue;
      let action = test.script.find((action) => action.action_id === actionId);
      if (action)
        return returnCopy ? JSON.parse(JSON.stringify(action)) : action;
    }

    throw new Error(`No action found with id ${actionId}`);
  }

  /**
   * Updates a property of a test action
   *
   * @param {Object} options - The function options.
   * @param {String} options.actionId - The ID of the test action to update
   * @param {String} options.key - The name of the property within the test
   * action to update
   * @param {any} [options.value] - The value to assign to the test action
   * @param {Object} [options.saveOptions] - The options to pass to the save function
   */
  setTestActionProperty({ actionId, key, value, saveOptions }) {
    const testAction = this.getTestAction({
      actionId: actionId,
      returnCopy: false,
    });

    if (getNestedValue(testAction, key) !== value) {
      setNestedValue(testAction, key, JSON.parse(JSON.stringify(value)));
      return this.save(saveOptions);
    } else {
      if (saveOptions?.saveCompleteCallback) {
        saveOptions.saveCompleteCallback(true);
      }
      console.debug(
        `Skipping test action save because the value for ${key} was the same.`
      );
    }
  }

  /**
   * Returns a property of a test action
   *
   * @param {Object} options - The function options.
   * @param {String} options.actionId - The ID of the test action
   * @param {String} options.key - The name of the property within the test action
   */
  getTestActionProperty({ actionId, key }) {
    const testAction = this.getTestAction({
      actionId: actionId,
      returnCopy: false,
    });

    return getNestedValue(testAction, key);
  }

  /**
   * Method to set a new test action.
   *
   * @param {Object} newAction - The new test action object.
   * @param {Object} saveOptions - Options passed to the save callback
   * @returns {any} - The result of the saveCallback() if a new action is added
   * otherwise undefined is returned
   */
  setTestAction(newAction, saveOptions) {
    // Iterate over all tests
    for (let test of this.object.tests) {
      if (!("script" in test)) continue;
      let index = test.script.findIndex((action) => {
        console.log(action.action_id, action.action_id);
        return action.action_id === newAction.action_id;
      });

      if (index !== -1) {
        let oldAction = test.script[index];
        console.log("OLD:", oldAction, "\nNEW", newAction);
        // Check if the new action is different from the old action
        if (JSON.stringify(oldAction) !== JSON.stringify(newAction)) {
          test.script[index] = JSON.parse(JSON.stringify(newAction));
          return this.save(saveOptions);
        } else {
          if (saveOptions?.saveCompleteCallback) {
            saveOptions.saveCompleteCallback(true);
          }
          console.log(
            "Skipping set test action save and setting because actions are the same."
          );
        }
        return;
      }
    }

    throw new Error(`No action found with id ${newAction.action_id}`);
  }

  /**
   * Method to add a new test to the tests array.
   *
   * @param {Object} newTest - The new test object.
   * @param {number} limit - The maximum allowed number of tests.
   * @param {Object} saveOptions - Options passed to the save callback
   */
  addTest(newTest, limit, saveOptions) {
    if (this.object.tests.length >= limit) {
      throw new Error(`Cannot add more than ${limit} tests.`);
    }

    const toAdd = JSON.parse(JSON.stringify(newTest));
    this.object.tests.push(toAdd);
    console.log("NEW TESTs", this.object.tests);
    return this.save(saveOptions);
  }

  /**
   * Method to remove a test from the tests array.
   *
   * @param {string} test_id - The ID of the test to remove.
   * @param {Object} saveOptions - Options passed to the save callback
   * @returns {any} - The result of the saveCallback()
   */
  removeTest(test_id, saveOptions) {
    const testIndex = this.object.tests.findIndex(
      (test) => test.test_id === test_id
    );

    if (testIndex === -1) {
      throw new Error(`No test found with id ${test_id}`);
    }

    this.object.tests.splice(testIndex, 1);
    return this.save(saveOptions);
  }

  /**
   * Method to add a new test action to a test's script array.
   *
   * @param {Object} options - The function options.
   * @param {string} options.testId - The ID of the test to which the action should be added.
   * @param {Object} options.newAction - The new test action object.
   * @param {number} options.limit - The maximum allowed number of actions in a test script.
   * @param {Object} options.saveOptions - Options passed to the save callback
   * @returns {any} - The result of the saveCallback()
   */
  addTestAction({ testId, newAction, limit = 32, saveOptions }) {
    let test = this.object.tests.find((test) => test.test_id === testId);

    if (!test) {
      throw new Error(`No test found with id ${testId}`);
    }

    if (test.script.length >= limit) {
      throw new Error(
        `Cannot add more than ${limit} actions to a test script.`
      );
    }
    const toAdd = JSON.parse(JSON.stringify(newAction));
    test.script.push(toAdd);
    return this.save(saveOptions);
  }

  /**
   * Method to remove a test action from a test's script array.
   *
   * @param {Object} options - The function options.
   * @param {string} options.testId - The ID of the test from which the action should be removed.
   * @param {string} options.actionId - The ID of the action to remove.
   * @param {Object} options.saveOptions - Options passed to the save callback
   * @returns {any} - The result of the saveCallback()
   */
  removeTestAction({ testId, actionId, saveOptions }) {
    let test = this.object.tests.find((test) => test.test_id === testId);

    if (!test) {
      throw new Error(`No test found with id ${testId}`);
    }

    const actionIndex = test.script.findIndex(
      (action) => action.action_id === actionId
    );

    if (actionIndex === -1) {
      throw new Error(`No action found with id ${actionId}`);
    }

    test.script.splice(actionIndex, 1);
    return this.save(saveOptions);
  }

  /**
   * Method to remove a test action from a test's script array.
   *
   * @param {Object} options - The function options.
   * @param {string} options.testId - The ID of the test from which the action should be removed.
   * @param {string} options.actionId - The ID of the action to remove.
   * @param {number} options.movement - The number of spots to move the test
   * action's order
   * @param {Object} options.saveOptions - Options passed to the save callback
   * @returns {any} - The result of the saveCallback()
   */
  moveTestAction({ testId, actionId, movement, saveOptions }) {
    let test = this.object.tests.find((test) => test.test_id === testId);

    if (!test) {
      throw new Error(`No test found with id ${testId}`);
    }

    const actionIndex = test.script.findIndex(
      (action) => action.action_id === actionId
    );

    if (actionIndex === -1) {
      throw new Error(`No action found with id ${actionId}`);
    }

    moveItemByN(test.script, actionIndex, movement);
    return this.save(saveOptions);
  }
}

/**
 * CourseConfigurationSaveManager class for handling save operations related to
 * a course configuration. It extends from GeneralSaveManager
 */
export class CourseConfigurationSaveManager extends GeneralSaveManager {
  /**
   * Constructor for the CourseConfigurationSaveManager class.
   *
   * @param {Object} options - The function options.
   * @param {Object} options.object - The problem object.
   * @param {(...args: any[]) => any} options.saveCallback - The callback
   * function to be called on changes to the problem's properties.
   * @param {Function} [options.getLatestCallback] - An optional callback
   * function to fetch the latest version of the object.
   * @param {Object} options.schema - The JSON schema to use to validate the
   * information.
   */
  constructor({ object, saveCallback, getLatestCallback, schema }) {
    super({
      type: "CourseConfiguration",
      object: object,
      saveCallback: saveCallback,
      getLatestCallback: getLatestCallback,
      schema: schema,
    });

    this.courseConfigurationSchema = getSchema({
      objectName: "courseconfiguration",
      jsonSchema: this.schema,
    });
  }

  validate() {
    const valid = this.courseConfigurationSchema.validate(this.object);
    if (!valid) {
      console.debug(
        this.type + " Validation Errors:",
        this.courseConfigurationSchema.validate.errors
          .map((error) => error.instancePath + ": [" + error.message + "]")
          .join(", ")
      );
      throw new ValidationError(
        this.courseConfigurationSchema.validate.errors.map(
          (error) => error.message
        )
      );
    }
  }
}

/**
 * CourseConfigurationSaveManager class for handling save operations related to
 * a course configuration. It extends from GeneralSaveManager
 */
export class GradeFormulaSaveManager extends GeneralSaveManager {
  /**
   * Constructor for the GradeFormulaSaveManager class.
   *
   * @param {Object} options - The function options.
   * @param {Object} options.object - The grade formula object.
   * @param {(...args: any[]) => any} options.saveCallback - The callback
   * function to be called on changes to the grade formula's properties.
   * @param {Function} [options.getLatestCallback] - An optional callback
   * function to fetch the latest version of the grade formula.
   * @param {Object} options.schema - The JSON schema to use to validate the
   * information.
   */
  constructor({ object, saveCallback, getLatestCallback, schema }) {
    super({
      type: "GradeFormula",
      object: object,
      saveCallback: saveCallback,
      getLatestCallback: getLatestCallback,
      schema: schema,
    });

    this.courseConfigurationSchema = getSchema({
      objectName: "gradeformula",
      jsonSchema: this.schema,
    });
  }

  validate() {
    const valid = this.courseConfigurationSchema.validate(this.object);
    if (!valid) {
      console.debug(
        this.type + " Validation Errors:",
        this.courseConfigurationSchema.validate.errors
          .map((error) => error.instancePath + ": [" + error.message + "]")
          .join(", ")
      );
      throw new ValidationError(
        this.courseConfigurationSchema.validate.errors.map(
          (error) => error.message
        )
      );
    }
  }
}

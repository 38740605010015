import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Fade,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import BasicModal from "../BasicModal";
import { useEffect, useMemo, useRef, useState } from "react";
import ProblemSetSearchField from "./ProblemSetSearchField";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  getCourseConfig,
  postCourseConfig,
  postProblemSet,
} from "../../api/crud";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { to1159 } from "../problem-set/course-editor/utilities";
import { useAuth0 } from "@auth0/auth0-react";
const TEXT = require("../../text.json");

/**
 * AddProblemSetModal is a modal component for adding a new problem set to a
 * course. It allows the user to either create a new problem set or select an
 * existing one.
 *
 * @param {object} props Component props
 * @param {string} props.courseId The unique identifier for the course.
 * @param {boolean} props.open Indicates if the modal is open.
 * @param {function} props.setClosed Function to close the modal.
 * @param {function} props.onCreationComplete Callback function that executes
 * after a problem set is created or selected.
 */
function AddProblemSetModal({ courseId, open, setClosed, onCreationComplete }) {
  const [loadingSave, setLoadingSave] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [error, setError] = useState("");
  const [initError, setInitError] = useState(false);
  const [newProblemSetSelected, setNewProblemSetSelected] = useState(true);
  const [newProblemSetName, setNewProblemSetName] = useState("");
  const [selectedProblemSetId, setSelectedProblemSetId] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const excludedProblemSetIds = useRef([]);
  const dispatch = useDispatch();

  const theme = useTheme();

  const selectButtonCss = useMemo(
    () => ({
      color: theme.palette.grey[500],
      border: `2px solid ${theme.palette.grey[500]}`,
      minWidth: "40%",
      "&.Mui-selected": {
        fontWeight: 600,
        border: 3,
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        height: "105%",
      },
    }),
    [theme.palette.grey, theme.palette.success.main]
  );

  const newProblemSetForm = useMemo(() => {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mr: 6, ml: 6, minHeight: 120 }}
      >
        <Typography component={"p"} fontSize={"1rem"} sx={{ mb: 2 }}>
          {
            TEXT.components.course_schedule.add_problem_set_modal
              .new_problem_set_instructions
          }
        </Typography>
        <TextField
          label={
            TEXT.components.course_schedule.add_problem_set_modal
              .new_problem_set_textfield_placeholder
          }
          variant="outlined"
          fullWidth
          onChange={(e) => setNewProblemSetName(e.target.value)}
        />
      </Stack>
    );
  }, []);

  const selectProblemSetForm = useMemo(() => {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mr: 6, ml: 6, minHeight: 120 }}
      >
        <Typography component={"p"} fontSize={"1rem"}>
          {
            TEXT.components.course_schedule.add_problem_set_modal
              .select_problem_set_instructions
          }
        </Typography>
        <ProblemSetSearchField
          excludeProblemSets={excludedProblemSetIds.current}
          onChange={(problemSetId) => {
            setSelectedProblemSetId(problemSetId ? problemSetId : "");
          }}
          sx={{ width: "100%" }}
        />
      </Stack>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initLoading]);

  const problemSetSkeletonForm = useMemo(() => {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mr: 6, ml: 6, minHeight: 120 }}
      >
        <Skeleton
          variant="text"
          width={"50%"}
          sx={{ fontSize: "1rem", mb: 2 }}
        />
        <Skeleton variant="rounded" width={"100%"} height={56} />
      </Stack>
    );
  }, []);

  useEffect(() => {
    if (!open) return;
    setInitLoading(true);
    getAccessTokenSilently()
      .then(async (token) => {
        const courseConfigs = await getCourseConfig({
          params: { group_id: [courseId] },
          dispatch: dispatch,
          authToken: token,
        });
        excludedProblemSetIds.current = courseConfigs.map(
          (courseConfig) => courseConfig.problem_set_id
        );
        console.log("Excluded problem sets", excludedProblemSetIds.current);
      })
      .catch((e) => {
        console.error("Failed to load course's configurations", e);
        setError(
          TEXT.components.course_schedule.add_problem_set_modal
            .error_course_config_load_message
        );
        setInitError(true);
      })
      .finally(() => {
        setInitLoading(false);
      });
  }, [courseId, dispatch, getAccessTokenSilently, open]);

  return useMemo(
    () => (
      <BasicModal
        open={open}
        handleClose={setClosed}
        title="Add Problem Set"
        buttonArray={[
          <LoadingButton
            fullWidth
            loading={loadingSave}
            variant="contained"
            disabled={
              initError ||
              (newProblemSetSelected && !Boolean(newProblemSetName)) ||
              (!newProblemSetSelected && !Boolean(selectedProblemSetId))
            }
            // @ts-ignore
            color={"primaryLightBlue"}
            onClick={() => {
              setLoadingSave(true);
              setError("");

              getAccessTokenSilently()
                .then(async (token) => {
                  if (newProblemSetSelected) {
                    const newProblemSet = await postProblemSet({
                      data: { name: newProblemSetName },
                      dispatch: dispatch,
                      authToken: token,
                    });
                    return { problemSetId: newProblemSet.id, token: token };
                  } else {
                    return { problemSetId: selectedProblemSetId, token: token };
                  }
                })
                .then(async (data) => {
                  const today = new Date();
                  const twoWeeksFromNow = new Date(today);
                  twoWeeksFromNow.setDate(today.getDate() + 14);
                  to1159(twoWeeksFromNow);

                  const newCourseConfig = {
                    group_id: courseId,
                    problem_set_id: data.problemSetId,
                    due: twoWeeksFromNow,
                    visible: true,
                  };

                  const config = await postCourseConfig({
                    data: newCourseConfig,
                    authToken: data.token,
                    dispatch: dispatch,
                  });

                  return config;
                })
                .then((newCourseConfig) => onCreationComplete(newCourseConfig))
                .catch((e) => {
                  console.error("Failed to add problem set", e);
                  setError(
                    TEXT.components.course_schedule.add_problem_set_modal
                      .error_saving_message
                  );
                })
                .finally(() => setLoadingSave(false));
            }}
          >
            {TEXT.components.course_schedule.add_problem_set_modal.modal_title}
          </LoadingButton>,
          <Button
            variant="outlined"
            // @ts-ignore
            color="primaryLightBlue"
            onClick={() => setClosed()}
            fullWidth
          >
            {
              TEXT.components.course_schedule.add_problem_set_modal
                .cancel_button_title
            }
          </Button>,
        ]}
      >
        <Stack
          spacing={4}
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          sx={{ mb: 5 }}
        >
          <Collapse in={Boolean(error)}>
            <Alert severity="error">
              {
                TEXT.components.course_schedule.add_problem_set_modal
                  .error_saving_message
              }
            </Alert>
          </Collapse>
          <Stack
            direction="row"
            spacing={7}
            justifyContent="center"
            alignItems="center"
            sx={{ minWidth: "100%" }}
          >
            <Tooltip
              title={
                TEXT.components.course_schedule.add_problem_set_modal
                  .new_problem_set_button_tooltip
              }
              placement="top"
              enterDelay={700}
              describeChild
              arrow
            >
              <ToggleButton
                size="small"
                value="new"
                selected={newProblemSetSelected}
                disabled={initLoading}
                onChange={() => {
                  setNewProblemSetSelected(true);
                }}
                sx={{
                  ...selectButtonCss,
                  boxShadow: newProblemSetSelected ? 12 : 0,
                }}
              >
                {
                  TEXT.components.course_schedule.add_problem_set_modal
                    .new_problem_set_button_title
                }
              </ToggleButton>
            </Tooltip>
            <Tooltip
              title={
                TEXT.components.course_schedule.add_problem_set_modal
                  .existing_problem_set_button_tooltip
              }
              placement="top"
              enterDelay={700}
              describeChild
              arrow
            >
              <ToggleButton
                size="small"
                value="existing"
                disabled={initLoading}
                selected={!newProblemSetSelected}
                onChange={() => {
                  setNewProblemSetSelected(false);
                }}
                sx={{
                  ...selectButtonCss,
                  boxShadow: !newProblemSetSelected ? 12 : 0,
                }}
              >
                {
                  TEXT.components.course_schedule.add_problem_set_modal
                    .existing_problem_set_button_title
                }
              </ToggleButton>
            </Tooltip>
          </Stack>
          {initLoading ? (
            <Fade in={true}>{problemSetSkeletonForm}</Fade>
          ) : (
            <Fade in={true}>
              <Box>
                <Collapse in={newProblemSetSelected}>
                  {newProblemSetForm}
                </Collapse>
                <Collapse in={!newProblemSetSelected}>
                  {selectProblemSetForm}
                </Collapse>
              </Box>
            </Fade>
          )}
        </Stack>
        <Divider variant="middle" flexItem />
      </BasicModal>
    ),
    [
      courseId,
      dispatch,
      error,
      getAccessTokenSilently,
      initError,
      initLoading,
      loadingSave,
      newProblemSetForm,
      newProblemSetName,
      newProblemSetSelected,
      onCreationComplete,
      open,
      problemSetSkeletonForm,
      selectButtonCss,
      selectProblemSetForm,
      selectedProblemSetId,
      setClosed,
    ]
  );
}

AddProblemSetModal.propTypes = {
  courseId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setClosed: PropTypes.func.isRequired,
  onCreationComplete: PropTypes.func.isRequired,
};

AddProblemSetModal.defaultProps = {
  onCreationComplete: () => {},
};

export default AddProblemSetModal;

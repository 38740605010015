import { ThemeProvider } from "@emotion/react";
import { Box, styled, Tabs } from "@mui/material";
import { useCallback } from "react";
import { GT_THEME, GT_COLORS } from "../../styles/Styles";
import Tab from "@mui/material/Tab";

const TEXT = require("../../text.json");

const BasicTabs = ({ tabValue, handleTabChange, disabled=false }) => {
  const a11yProps = useCallback((tabId) => {
    return {
      id: `problem-set-tab-${tabId}`,
      "aria-controls": `problem-set-tabpanel-${tabId}`,
    };
  }, []);

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: GT_COLORS.primaryLightBlue,
    },
  });

  return (
    <ThemeProvider theme={GT_THEME}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label={TEXT.problem_set_editor.tabs_aria_label}
          TabIndicatorProps={{
            style: {
              color: GT_COLORS.primaryLightBlue,
              backgroundColor: GT_COLORS.primaryLightBlue,
            },
          }}
        >
          <StyledTab
            disabled={disabled}
            label={TEXT.problem_set_editor.general_tab_title}
            {...a11yProps(0)}
          />
          <StyledTab
            disabled={disabled}
            label={TEXT.problem_set_editor.problem_tab_title}
            {...a11yProps(1)}
          />
          <StyledTab
            disabled={disabled}
            label={TEXT.problem_set_editor.courses_tab_title}
            {...a11yProps(2)}
          />
          <StyledTab
            disabled={disabled}
            label={TEXT.problem_set_editor.submissions_tab_title}
            {...a11yProps(3)}
          />
          {/*TODO <Tab label={TEXT.problem_set_editor.data_tab_title} {...a11yProps(4)} /> */}
        </Tabs>
      </Box>
    </ThemeProvider>
  );
};

export default BasicTabs;

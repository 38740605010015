import Stack from "@mui/material/Stack";
import { Link, Typography } from "@mui/material";
import { GT_COLORS } from "../styles/Styles";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as Logo } from "../assets/logos/agt-line-logo.svg";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

import loadable from "@loadable/component";

const Barrier = loadable(() =>
  import(/* webpackPrefetch: true */ "../pages/Barrier")
);

loadable(() =>
  import(/* webpackPrefetch: true */ "../components/submission/Table")
);

loadable(() =>
  import(/* webpackPrefetch: true */ "../components/RecentProblemsSets")
);

loadable(() => import(/* webpackPrefetch: true */ "../pages/InstructorMain"));
loadable(() => import(/* webpackPrefetch: true */ "../pages/CoursesGallery"));

const TEXT = require("../text.json");

export default function Page(props) {
  const { loginWithRedirect } = useAuth0();
  const [buttonLoading, setButtonLoading] = useState(false);
  return (
    <Barrier pageTitle={TEXT.components.auth_bearer.page_title}>
      <Logo
        style={{ maxWidth: "90%", minWidth: "300px" }}
        title={TEXT.components.auth_bearer.logo_title}
      />

      <Typography fontSize={"1.4rem"} align="center">
        {TEXT.components.auth_bearer.message}
      </Typography>
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{ minWidth: 1 }}
        //direction="row"
      >
        <LoadingButton
          // @ts-ignore
          color={"primaryLightBlue"}
          loading={buttonLoading}
          sx={{
            minWidth: "50%",
            borderColor: GT_COLORS.primaryDarkBlue,
            color: GT_COLORS.primaryGrey,
          }}
          variant="contained"
          onClick={() => {
            setButtonLoading(true);
            loginWithRedirect().then(() => setButtonLoading(false));
          }}
          ariaLabel={TEXT.components.auth_bearer.login_button_name}
        >
          {TEXT.components.auth_bearer.login_button_name}
        </LoadingButton>
        <Link
          href="https://www.graderthan.com/signup/"
          target="_blank"
          fontSize={"1rem"}
          color={GT_COLORS.primaryDarkBlue}
        >
          {TEXT.components.auth_bearer.sign_up_button_name}
        </Link>
      </Stack>
    </Barrier>
  );
}

import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  getProblem,
  getProblemSet,
  handleRequestError,
  patchProblem,
  patchProblemSet,
  postProblemSet,
} from "../api/crud";
import { Fade, Stack } from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { AddOutlinedIcon } from "../styles/icons";
import {
  setInfo as setErrorModalInfo,
  setOpen as openErrorModal,
} from "../slices/error";
import { useDispatch } from "react-redux";
import { ProblemSetSaveManager } from "../api/savers";
import { getEditTabbedUrl } from "./page-routing";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty, makePath } from "../utils";
import { useNavigate } from "react-router-dom";

import BasicTabs from "../components/problem-set/BasicTabs";

import loadable from "@loadable/component";
import ContentLoader from "react-content-loader";
import { CONTENT_LOADER_DEFAULT_PROPS } from "../styles/Styles";
import { isNil } from "lodash";

const LinearProgress = loadable(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ "../components/LinearProgress")
);

const IconButton = loadable(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ "../components/IconButton")
);

// @ts-ignore
const GeneralBase = loadable(
  () =>
    import(/* webpackPrefetch: true */ "../components/problem-set/GeneralBase"),
  {
    fallback: (
      <Fade in={true} timeout={300}>
        <Box>
          <ContentLoader
            style={{ width: "100%", height: "700px" }}
            {...CONTENT_LOADER_DEFAULT_PROPS}
          >
            <rect x="0" y="20" rx="3" ry="3" width="70%" height="50" />
            <rect x="80%" y="20" rx="3" ry="3" width="20%" height="50" />

            <rect x="0" y="100" rx="3" ry="3" width="100%" height="400" />
          </ContentLoader>
        </Box>
      </Fade>
    ),
  }
);
// @ts-ignore
const Page = loadable(() => import(/* webpackPrefetch: true */ "./Page"));
const ProblemsBase = loadable(
  () =>
    import(/* webpackPrefetch: true */ "../components/problem-set/ProblemBase"),
  {
    fallback: (
      <Fade in={true} timeout={300}>
        <Box>
          <ContentLoader
            style={{ width: "100%", height: "70vh" }}
            {...CONTENT_LOADER_DEFAULT_PROPS}
          >
            <rect x="0" y="20" rx="3" ry="3" width="100%" height="50" />
            <rect x="0" y="100" rx="3" ry="3" width="100%" height="500" />
          </ContentLoader>
        </Box>
      </Fade>
    ),
  }
);

const BasicModal = loadable(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ "../components/BasicModal")
);
const CreateProblem = loadable(() =>
  // @ts-ignore
  import(
    /* webpackPrefetch: true */ "../components/problem-set/problem-editor/CreateProblem"
  )
);
// @ts-ignore
const CourseBase = loadable(
  () =>
    import(/* webpackPrefetch: true */ "../components/problem-set/CourseBase"),
  {
    fallback: (
      <Fade in={true} timeout={300}>
        <Box sx={{ width: "100%", height: "70vh" }}>
          <ContentLoader
            style={{ width: "100%", height: "70vh" }}
            {...CONTENT_LOADER_DEFAULT_PROPS}
          >
            <rect x="15%" y="30" rx="3" ry="3" width="70%" height="50" />
            <rect x="25%" y="120" rx="3" ry="3" width="50%" height="40" />
          </ContentLoader>
        </Box>
      </Fade>
    ),
  }
);

const SaveState = loadable(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ "../components/problem-set/SaveState")
);
// @ts-ignore
const SubmissionBase = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../components/problem-set/SubmissionsBase"
    ),
  {
    fallback: (
      <Fade in={true} timeout={300}>
        <Box>
          <ContentLoader
            style={{ width: "100%", height: "100vh" }}
            {...CONTENT_LOADER_DEFAULT_PROPS}
          >
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="90" />
            {[...Array(9).keys()].map((i) => (
              <rect
                key={"submission-table-loader-" + i}
                x="0"
                y={`${100 + i * 70}`}
                rx="3"
                ry="3"
                width="100%"
                height="60"
              />
            ))}
          </ContentLoader>
        </Box>
      </Fade>
    ),
  }
);
const StudentBarrier = loadable(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ "../components/problem-set/StudentBarrier")
);

export const TAB_GENERAL = "general";
export const TAB_PROBLEMS = "problems";
export const TAB_COURSES = "courses";
export const TAB_SUBMISSIONS = "submissions";
export const TAB_DATA = "data";

export const PAGE_STATE_LOADING = "loading";
export const PAGE_STATE_COMPLETE = "complete";
export const PAGE_STATE_ERROR = "error";

const TEXT = require("../text.json");

function TabPanel(props) {
  const { children, selectedTab, tabId, ...other } = props;
  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={selectedTab !== tabId}
      id={`problem-set-tabpanel-${tabId}`}
      aria-labelledby={`problem-set-tab-${tabId}`}
      {...other}
    >
      {selectedTab === tabId && (
        <Box sx={{ width: "100%", pt: 3 }}>{children}</Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  tabId: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

const InternalProblemSetEditorPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { problemSetId, tabName, resourceId, testId } = useParams();

  const [problemSetLoaded, setProblemSetLoaded] = useState(false);
  const [openAddProbModal, setOpenAddProbModal] = useState(false);

  const problemBaseRefreshCallbackRef = useRef(() => {});

  const selectedProblemIdRef = useRef(
    tabName === TAB_PROBLEMS && resourceId ? resourceId : ""
  );

  const selectedCourseIdRef = useRef(
    tabName === TAB_COURSES && resourceId ? resourceId : ""
  );
  const [renderProblemTab, reRenderProblemTab] = useReducer((x) => x + 1, 0);

  const selectedTestIdRef = useRef(testId || "");

  const problemSetRef = useRef(null);
  const makeProblemSetLockRef = useRef(false);

  const problemSetSaveManagerRef = useRef();

  const loadState = useRef(PAGE_STATE_LOADING);
  const dispatch = useDispatch();

  // @ts-ignore
  const schema = useSelector((state) => state.appState.schema);
  // @ts-ignore
  const config = useSelector((state) => state.appState.config);

  const [problemEditorLoading, setProblemEditorLoading] =
    useState(PAGE_STATE_COMPLETE);

  if (problemSetId && !tabName) {
    window.history.replaceState(
      null,
      "Problem Set Editor",
      getEditTabbedUrl({ tabName: TAB_GENERAL, problemSetId: problemSetId })
    );
  }

  let setSavingState = useRef((message) => {});
  let setErrors = useRef((message) => {});
  let [searchParams, setSearchParams] = useSearchParams();

  const homeCourse = searchParams.get("homeCourse");
  const enableBackButton = isNil(searchParams.get("hideBackBtn"));

  const valueToTabName = useMemo(() => {
    return {
      0: TAB_GENERAL,
      1: TAB_PROBLEMS,
      2: TAB_COURSES,
      3: TAB_SUBMISSIONS,
      4: TAB_DATA,
    };
  }, []);

  const tabNameToValue = useMemo(() => {
    const result = {};
    result[TAB_GENERAL] = 0;
    result[TAB_PROBLEMS] = 1;
    result[TAB_COURSES] = 2;
    result[TAB_SUBMISSIONS] = 3;
    result[TAB_DATA] = 4;
    return result;
  }, []);

  // const [tabValue, setTabValue] = useState(
  //   tabNameToValue[tabName || TAB_GENERAL]
  // );
  const tabValue = tabNameToValue[tabName || TAB_GENERAL];

  console.log(`RENDERED - Problem Set Editor
  \tDOC ID: ${problemSetId}
  \tPATH:   ${window.location.pathname}
  \tTAB:    ${tabNameToValue[tabName || TAB_GENERAL]}:${tabName}`);

  /**
   * This `useEffect` hook sets up an event listener to update the selected tab
   * in response to the forward/back button presses (or direct URL changes) in the
   * browser. When the URL changes, it:
   * 1. Extracts the last segment of the URL path.
   * 2. Maps this segment to a corresponding tab value (if it exists in the
   *    mapping).
   * 3. Updates the state to reflect the active tab based on the URL.
   *
   * Furthermore, it ensures that the event listener is properly cleaned up when
   * the component using this hook is unmounted.
   */
  useEffect(() => {
    // Handler for changes in the browser's history
    const handleHistoryChange = (event) => {
      // Extract the last segment of the current URL
      const path = window.location.pathname;

      // Regex pattern to extract the tab name
      const regex = /\/problem-set\/edit\/[^/]+\/([^/]+)/;
      const match = path.match(regex);

      let tabName = match && match[1] ? match[1] : TAB_GENERAL;

      // Default to the general tab if the url does not match a tab.
      tabName = tabName in tabNameToValue ? tabName : TAB_GENERAL;
      console.debug(`History changed:
      \tPATH: ${path}
      \tTAB NAME: ${tabName}`);
      // Update the component's state to reflect the active tab
      //setTabValue(tabNameToValue[tabName]);
      const newPath = getEditTabbedUrl({
        tabName: tabName,
        problemSetId: problemSetId,
      });
    };

    // Add the handler as an event listener for the "popstate" event, which
    // is fired when the active history entry changes
    window.addEventListener("popstate", handleHistoryChange);

    // Cleanup: remove the event listener when the component is unmounted.
    return () => {
      window.removeEventListener("popstate", handleHistoryChange);
    };
  }, [navigate, problemSetId, tabNameToValue]);

  // Used to update a particular tabbed page's status
  const updatePageState = useCallback(
    (newState, tabNameAct) => {
      const stateCallbacks = {};
      loadState.current = newState;
      var tabName = tabNameAct;

      if (!tabName) tabName = valueToTabName[tabValue];

      const callback = stateCallbacks[tabName];
      if (callback) callback(loadState.current);
    },
    [tabValue, valueToTabName]
  );

  const handleMissingProblemSet = useCallback(() => {
    const navUrl = makePath(`/`, searchParams);
    console.error("Problem set missing");
    navigate(navUrl);
    dispatch(
      setErrorModalInfo({
        title: TEXT.error.resource_not_found_title,
        message: TEXT.problem_set_editor.problem_set_not_found_message,
      })
    );
    dispatch(openErrorModal());
  }, [dispatch, navigate, searchParams]);

  const saveCallback = useCallback(
    async (manager, options, setNewObj) =>
      await getAccessTokenSilently().then(async (token) => {
        let saveFunction;
        const type = manager.type;
        const object = manager.object;

        if (type === "Problem") {
          saveFunction = async (object) => {
            const newProblem = await patchProblem({
              data: object,
              authToken: token,
              dispatch: dispatch,
            });
            setNewObj(newProblem);
          };
        } else if (type === "ProblemSet") {
          saveFunction = async (object) => {
            const newPs = await patchProblemSet({
              dispatch: dispatch,
              data: object,
              authToken: token,
            });
            setNewObj(newPs);
          };
        }

        setSavingState.current("saving");

        const errors = [];
        try {
          await saveFunction(object);
          setTimeout(() => setSavingState.current("saved"), 1000);
        } catch (err) {
          errors.push(TEXT.error.general_issue_message);
          console.error(err);
        } finally {
          if (errors.length) console.error(errors);
          if (options.saveCompleteCallback) {
            options.saveCompleteCallback(!Boolean(errors.length));
          }
        }

        if (errors.length) {
          errors.forEach((error) =>
            console.error("Validation Error -> ", error)
          );
          setSavingState.current("error");
        }
        setErrors.current(errors);
      }),
    [dispatch, getAccessTokenSilently]
  );

  const updateProblemSaveManagers = useCallback(
    async ({
      problemSetSaveManager,
      startCallback = () => {},
      completeCallback = () => {},
      forceRefresh = false,
    }) => {
      console.debug("Updating problem save managers");
      if (forceRefresh) {
        // Delete all current save managers to force refresh
        for (let member in problemSetSaveManager.loaded_problems)
          delete problemSetSaveManager.loaded_problems[member];
      }

      // Check if a refresh is needed
      if (problemSetSaveManager.isProblemsInit()) {
        console.debug(
          "Skipped update problem save manager because problems are initiated."
        );
        setProblemEditorLoading(PAGE_STATE_COMPLETE);
        completeCallback();
        return;
      }

      // Set the problem set editor to lodaing screen
      setProblemEditorLoading(PAGE_STATE_LOADING);
      startCallback();

      const problemIds = problemSetSaveManager.getProperty("problems") || [];

      // clear the current save managers
      for (let member in problemSetSaveManager.loaded_problems)
        delete problemSetSaveManager.loaded_problems[member];

      await getAccessTokenSilently().then((token) =>
        getProblem({
          params: { resource_id: problemIds },
          authToken: token,
          dispatch: dispatch,
        }).then((problems) => {
          const problemGetter = (problemId) => {
            const toRetProb = problems.find((problem) => {
              return problem.id === problemId;
            });
            return toRetProb;
          };

          problemSetSaveManager.initProblemSaveManagers({
            problemGetter: problemGetter,
            getLatestCallback: async (problemId) =>
              await getAccessTokenSilently().then(async (token) => {
                const v = await getProblem({
                  params: { resource_id: [problemId] },
                  authToken: token,
                  dispatch: dispatch,
                  cacheConfig: { override: true },
                });
                return v[0];
              }),
          });
        })
      );
      setProblemEditorLoading(PAGE_STATE_COMPLETE);
      completeCallback();
    },
    [dispatch, getAccessTokenSilently]
  );

  // Load the problem set
  useEffect(() => {
    console.debug(`
    ------------------
    Problem set Id:
    URL ID:     ${problemSetId}
    CURRENT ID: ${problemSetRef.current?.id}
    RELOAD:     ${problemSetRef.current?.id !== problemSetId}
    ------------------
    `);

    if (isEmpty(config.data) || isEmpty(schema.data)) {
      setProblemEditorLoading(PAGE_STATE_LOADING);
    }

    if (problemSetId && problemSetRef.current?.id !== problemSetId) {
      setProblemSetLoaded(false);
      getAccessTokenSilently().then((token) =>
        getProblemSet({
          dispatch: dispatch,
          params: { resource_id: [problemSetId] },
          authToken: token,
          cacheConfig: { override: true },
        })
          .then((problemSets) => {
            if (problemSets?.length) {
              problemSetRef.current = problemSets[0];

              // @ts-ignore
              problemSetSaveManagerRef.current = new ProblemSetSaveManager({
                object: problemSets[0],
                saveCallback: saveCallback,
                schema: schema.data,
                getLatestCallback: async () => {
                  const newPS = (
                    await getProblemSet({
                      dispatch: dispatch,
                      params: { resource_id: [problemSetId] },
                      authToken: token,
                      cacheConfig: { override: true },
                    })
                  )[0];

                  return newPS;
                },
              });

              updateProblemSaveManagers({
                problemSetSaveManager: problemSetSaveManagerRef.current,
              });

              setProblemSetLoaded(true);
              console.debug(`ProblemSet ${problemSetId} loaded`);
            } else {
              handleMissingProblemSet();
              setProblemSetLoaded(false);
            }
          })
          .catch((error) => {
            handleRequestError(dispatch, error);
          })
      );
    } else if (!isEmpty(config.data) && !isEmpty(schema.data)) {
      updatePageState(PAGE_STATE_COMPLETE);
      setProblemSetLoaded(true);
    }
  }, [
    config.data,
    dispatch,
    getAccessTokenSilently,
    handleMissingProblemSet,
    problemSetId,
    saveCallback,
    schema.data,
    updatePageState,
    updateProblemSaveManagers,
  ]);

  const makeProblemSet = useCallback(async () => {
    if (makeProblemSetLockRef.current) return;
    makeProblemSetLockRef.current = true;
    try {
      const newProblemSet = await getAccessTokenSilently().then((token) =>
        postProblemSet({
          dispatch: dispatch,
          authToken: token,
        })
      );
      console.debug("Created new Problem-Set[" + newProblemSet.id + "]");
      problemSetRef.current = newProblemSet;
    } finally {
      makeProblemSetLockRef.current = false;
    }
  }, [dispatch, getAccessTokenSilently]);

  const setSaveStateCallback = (setSavingStateFunc, setErrorsFunc) => {
    setSavingState.current = setSavingStateFunc;
    setErrors.current = setErrorsFunc;
  };

  const setSelectedProblemId = useCallback(
    (problemId) => {
      console.debug("Set new problem ID value:", problemId);
      selectedProblemIdRef.current = problemId;
      const newPath = getEditTabbedUrl({
        tabName: TAB_PROBLEMS,
        problemSetId: problemSetId,
        resourceId: problemId,
      });
      window.history.replaceState(null, "Problem Set Editor", newPath);
    },
    [problemSetId]
  );

  const handleTabChange = (event, newTabValue) => {
    //setTabValue(newTabValue);
    const tabName = valueToTabName[newTabValue];
    console.debug("Set new tab name:", tabName);
    const newPath = getEditTabbedUrl({
      tabName: tabName,
      problemSetId: problemSetId,
    });
    navigate(newPath);
    window.scrollTo(0, 0);
    //window.history.pushState(null, "Problem Set Editor", newPath);
  };

  const generalBase = useMemo(
    () => (
      <GeneralBase
        makeProblemSet={makeProblemSet}
        problemSetSaveManagerRef={problemSetSaveManagerRef}
      />
    ),
    [makeProblemSet, problemSetSaveManagerRef]
  );

  const problemBase = useMemo(() => {
    const setSelectedTestId = (testId) => {
      console.debug("Set new test ID value:", testId);
      selectedTestIdRef.current = testId;
      const newPath = getEditTabbedUrl({
        tabName: TAB_PROBLEMS,
        problemSetId: problemSetId,
        resourceId: selectedProblemIdRef.current,
        testId: testId,
      });
      window.history.replaceState(null, "Problem Set Editor", newPath);
    };
    return (
      <ProblemsBase
        makeProblemSet={makeProblemSet}
        updateProblemSaveManagers={updateProblemSaveManagers}
        setSelectedTestId={setSelectedTestId}
        selectedTestIdRef={selectedTestIdRef}
        setSelectedProblemId={setSelectedProblemId}
        selectedProblemIdRef={selectedProblemIdRef}
        problemSetSaveManagerRef={problemSetSaveManagerRef}
        problemBaseRefreshCallbackRef={problemBaseRefreshCallbackRef}
        problemEditorLoading={problemEditorLoading}
      />
    );
  }, [
    renderProblemTab,
    makeProblemSet,
    updateProblemSaveManagers,
    setSelectedProblemId,
    problemEditorLoading,
    problemSetId,
  ]);

  const courseBase = useMemo(() => {
    const setSelectedCourseId = (courseId) => {
      selectedCourseIdRef.current = courseId;
      const newPath = getEditTabbedUrl({
        tabName: TAB_COURSES,
        problemSetId: problemSetId,
        resourceId: courseId,
      });
      window.history.replaceState(null, "Problem Set Editor", newPath);
    };

    return (
      <CourseBase
        problemSetSaveManagerRef={problemSetSaveManagerRef}
        selectedCourseId={selectedCourseIdRef.current}
        setSelectedCourseId={setSelectedCourseId}
        setSavingStateRef={setSavingState}
        setErrorsRef={setErrors}
      />
    );
  }, [problemSetId, selectedCourseIdRef, problemSetSaveManagerRef]);

  const submissionsBase = useMemo(
    () => <SubmissionBase problemSetId={problemSetId} />,
    [problemSetId]
  );

  return (
    <Page
      pageTitle={TEXT.problem_set_editor.page_title}
      enableBackButton={enableBackButton}
      backButtonCallback={() =>
        navigate(homeCourse ? `/?homeCourse=${homeCourse}` : "/")
      }
      tabs={BasicTabs({
        tabValue: tabValue,
        handleTabChange: handleTabChange,
        disabled: !Boolean(problemSetSaveManagerRef.current),
      })}
      tools={[
        <IconButton
          key={"problem-set-editor-add-btn"}
          icon={AddOutlinedIcon}
          disabled={!Boolean(problemSetSaveManagerRef.current)}
          ariaLabel={TEXT.problem_set_editor.add_button_aria_label}
          tooltip={TEXT.problem_set_editor.add_button_aria_label}
          onClick={(e) => {
            e.stopPropagation();
            setOpenAddProbModal(true);
          }}
        />,
      ]}
    >
      <Box sx={{ width: 1 }}>
        {!problemSetLoaded ? (
          <LinearProgress sx={{ mt: 3 }} />
        ) : (
          <Stack spacing={0.5}>
            <BasicModal
              open={openAddProbModal}
              handleClose={() => setOpenAddProbModal(false)}
              title={"Add Problem"}
              sx={{ maxHeight: "400px", maxWidth: "300px", minHeight: "350px" }}
            >
              <CreateProblem
                problemSetSaveManagerRef={problemSetSaveManagerRef}
                openProblemBaseCallback={(newSelectedProblemId) => {
                  setSelectedProblemId(newSelectedProblemId);
                  updateProblemSaveManagers({
                    problemSetSaveManager: problemSetSaveManagerRef.current,
                    forceRefresh: true,
                    completeCallback: () => {
                      problemBaseRefreshCallbackRef.current();
                      reRenderProblemTab();
                    },
                  });
                  //setTabValue(1);
                  const newPath = getEditTabbedUrl({
                    tabName: TAB_PROBLEMS,
                    problemSetId: problemSetId,
                    resourceId: newSelectedProblemId,
                  });
                  navigate(newPath);
                  setOpenAddProbModal(false);
                }}
              />
            </BasicModal>

            <SaveState onMount={setSaveStateCallback} />
            <Box>
              <TabPanel selectedTab={tabValue} tabId={0}>
                {generalBase}
              </TabPanel>
              <TabPanel selectedTab={tabValue} tabId={1}>
                {problemBase}
              </TabPanel>
              <TabPanel selectedTab={tabValue} tabId={2}>
                {courseBase}
              </TabPanel>
              <TabPanel selectedTab={tabValue} tabId={3}>
                {submissionsBase}
              </TabPanel>
            </Box>
          </Stack>
        )}
      </Box>
    </Page>
  );
};

const ProblemSetEditorPage = () => {
  // @ts-ignore
  const isTeacher = useSelector((state) => state.user.userInfo.isTeacher);
  const studentPreviewMode = useSelector(
    // @ts-ignore
    (state) => state.appState.studentPreviewMode
  );
  // @ts-ignore
  const isStudentView = !isTeacher || (studentPreviewMode && isTeacher);

  return isStudentView ? <StudentBarrier /> : <InternalProblemSetEditorPage />;
};

export default ProblemSetEditorPage;

import a11yEmoji from "@fec/remark-a11y-emoji";
import ReactMarkdown from "react-markdown";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import CodeCopyBtn from "./CopyButton";
import { Stack } from "@mui/material";
import { GT_COLORS } from "../../styles/Styles";
import remarkMermaidPlugin from "remark-mermaid-plugin";
import rehypeRaw from "rehype-raw";
import rehypeStringify from "rehype-stringify";
import { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";

const Markdown = ({ content }) => {
  // Add the CodeCopyBtn component to our PRE element
  const Pre = useCallback(({ children }) => {
    const isDiagram = (children) => {
      const className = children[0]?.props?.className || "";

      if (!className) return false;

      const match = /^(hljs )?language-mermaid/.test(
        className.toLocaleLowerCase()
      );
      return match;
    };

    return (
      <pre className="md-pre">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={0}
          sx={{
            border: "solid",
            background: "white",
            borderWidth: "1px",
            borderRadius: "4px",
            borderColor: GT_COLORS.primaryLightBlue,
            textAlign: isDiagram(children) ? "center" : "none",
          }}
        >
          {!isDiagram(children) && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <CodeCopyBtn>{children}</CodeCopyBtn>
            </Stack>
          )}
          {children}
        </Stack>
      </pre>
    );
  }, []);

  return useMemo(() => {
    const extendedVS = {
      ...vs,
      'pre[class*="language-"]': {
        margin: "0px 10px 15px 10px",
        padding: 0,
      },
    };

    return (
      <>
      <Helmet>
        <style type="text/css">{`
    .single-line-md-code {
      display: inline-block;
      padding: .25em .25em;
      background: #fff;
      color: #0000FF;
      border-radius: 4px;
    }
    code {
      font-size: 1rem !important;
    }
    `}</style>
      </Helmet>
        <ReactMarkdown
          children={content}
          remarkPlugins={[
            remarkGfm,
            remarkMath,
            a11yEmoji,
            remarkMermaidPlugin,
          ]}
          rehypePlugins={[rehypeKatex, rehypeRaw, rehypeStringify]}
          components={{
            pre: Pre,
            code({ node, inline, className = "md-code", children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              const notMermaid = !/^(hljs )?language-mermaid/.test(
                className.toLocaleLowerCase()
              );

              if (!notMermaid) console.log(node, children);

              return !inline && match && notMermaid ? (
                <SyntaxHighlighter
                  {...props}
                  children={String(children).replace(/\n$/, "")}
                  style={extendedVS}
                  language={match[1]}
                  PreTag="div"
                />
              ) : (
                <code {...props} className={"single-line-md-code"}>
                  {children}
                </code>
              );
            },
          }}
        />
            </>
    );
  }, [content]);
};

export default Markdown;

import ReactDOM from "react-dom/client";
import "./index.css";
import { GLOBAL_CSS, GT_THEME } from "./styles/Styles";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import createStoreFunction from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Missing404Icon } from "./styles/icons";
import figlet from "figlet";
import ansiShadow from "figlet/importable-fonts/ANSI Shadow.js";
import endent from "endent";
import loadable from "@loadable/component";
import Root from "./pages/Root";
import Main from "./pages/Main";
import LogoutBarrier from "./pages/LogoutBarrier";

// Console Welcome
const showWelcome = async () => {
  figlet.parseFont("Standard", ansiShadow);
  const yearString = new Date().getFullYear().toString();

  console.log(endent`
  ================================================================
  ${figlet.textSync("AGT", { font: "Standard" })}
  Auto Grader Than
  © ${yearString} Grader Than Technology LLC

  ----------------------------------------------------------------

  STAGE:             ${window["agt_stage"] || "<unset>"}
  GIT SHA:           ${window["agt_git_sha"] || "<unset>"}
  API HOST:          ${window["agt_api_host"] || "<unset>"}
  API VERSION:       ${window["agt_api_version"] || "<unset>"}
  USE TSL:           ${window["agt_api_use_tsl"] || "<unset>"}
  AUTH AUD:          ${window["agt_auth0_audience"] || "<unset>"}
  AUTH CLIENT ID:    ${window["agt_auth0_client_id"] || "<unset>"}
  AUTH DOMAIN:       ${window["agt_auth0_domain"] || "<unset>"}
  USE LOGGING:       ${window["agt_log_token"] ? "Yes" : "No"}

  ---------------------------------------------------------------

  “Teachers open the door, but you must enter by yourself.”
  ~ Chinese Proverb
  ===============================================================
  `);
};

const TEXT = require("./text.json");
const ProblemSetEditor = loadable(() =>
  import(/* webpackPrefetch: true */ "./pages/ProblemSetEditor")
);
const SubmissionPage = loadable(() =>
  import(/* webpackPrefetch: true */ "./pages/Submission")
);
const CoursesGalleryPage = loadable(() =>
  import(/* webpackPrefetch: true */ "./pages/CoursesGallery")
);
const CoursesSoloPage = loadable(() =>
  import(/* webpackPrefetch: true */ "./pages/CourseSolo")
);
const GeneralBarrier = loadable(() =>
  import(/* webpackPrefetch: true */ "./pages/GeneralBarrier")
);
const Logout = loadable(() =>
  import(/* webpackPrefetch: true */ "./components/Logout")
);

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    //loader: rootLoader,
    children: [
      {
        path: "",
        element: <Main />,
      },
      {
        path: "/problem-set/:problemSetId/edit/",
        element: <ProblemSetEditor />,
      },
      {
        path: "/problem-set/:problemSetId/edit/:tabName",
        element: <ProblemSetEditor />,
      },
      {
        path: "/problem-set/:problemSetId/edit/:tabName/:resourceId",
        element: <ProblemSetEditor />,
      },
      {
        path: "/problem-set/:problemSetId/edit/:tabName/:resourceId/test/:testId",
        element: <ProblemSetEditor />,
      },
      {
        path: "/problem-set/:problemSetId/submit/:courseId/",
        element: <SubmissionPage />,
      },
      {
        path: "/problem-set/:problemSetId/submit/:courseId/:tabName",
        element: <SubmissionPage />,
      },
      {
        path: "/problem-set/:problemSetId/submit/:courseId/submit/:problemId",
        element: <SubmissionPage />,
      },
      {
        path: "/problem-set/:problemSetId/submit/:courseId/results/:problemId",
        element: <SubmissionPage />,
      },
      {
        path: "/problem-set/:problemSetId/submit/:courseId/results/:problemId/:submissionId",
        element: <SubmissionPage />,
      },
      {
        path: "/course",
        element: <CoursesGalleryPage />,
      },
      {
        path: "/course/:courseId",
        element: <CoursesSoloPage />,
      },
      {
        path: "*",
        element: (
          <GeneralBarrier
            pageTitle={TEXT.general_barrier["404_message_page_title"]}
            message={TEXT.general_barrier["404_message"]}
            icon={Missing404Icon}
          />
        ),
      },
    ],
  },
  {
    path: "/logout",
    element: <LogoutBarrier />,
  },
]);

const { store, persistor } = createStoreFunction();

document.title = "Auto Grader Than";

fetch(process.env.PUBLIC_URL + "/env0.json")
  .then((response) => {
    if (!response.ok) {
      console.error(
        "Error fetching env.json. Status:",
        response.status,
        "Reason:",
        response.text()
      );
    }
    return response.json();
  })
  .then((data) => {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        window[`agt_${key}`] = data[key];
      }
    }
    window["agt_auth0_audience"] =
    process.env.REACT_APP_AUTH0_AUDIENCE || window["agt_auth0_audience"];

    window["agt_api_host"] =
      process.env.REACT_APP_API_HOST || window["agt_api_host"];

      window[`agt_api_use_tsl`] = ["1", "y", "yes", "true"].includes(
      (
        process.env.REACT_APP_API_USE_TSL ||
        window["agt_api_use_tsl"] ||
        "y"
      ).toLocaleLowerCase()
    );

    window["agt_api_http_protocol"] = window[`agt_api_use_tsl`]
      ? "https"
      : "http";

      window["agt_api_ws_protocol"] = window[`agt_api_use_tsl`] ? "wss" : "ws";

    window["agt_api_http_base_url"] = `${window["agt_api_http_protocol"]}://${
      process.env.REACT_APP_API_HOST || window["agt_api_host"]
    }`;

    window["agt_api_ws_base_url"] = `${window["agt_api_ws_protocol"]}://${
      process.env.REACT_APP_API_HOST || window["agt_api_host"]
    }`;
  })
  .then(showWelcome).then(() => {
    if (window["agt_stage"] === 'production') {
      console.log = () => {}
      console.debug = () => {}
    }
  })
  .then(() => {
    root.render(
      <Auth0Provider
        domain={window["agt_auth0_domain"]}
        clientId={window["agt_auth0_client_id"]}
        authorizationParams={{
          audience: window["agt_auth0_audience"],
          redirect_uri: window.location.origin,
        }}
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
        cacheLocation={"localstorage"}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={GT_THEME}>
                {GLOBAL_CSS}
                <RouterProvider router={router} />
              </ThemeProvider>
          </PersistGate>
        </Provider>
      </Auth0Provider>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

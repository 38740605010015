import { Fragment, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { GT_COLORS } from "../../styles/Styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { TAB_COURSES } from "../../pages/ProblemSetEditor";
import { makePath, useStudentView } from "../../utils";

const TEXT = require("../../text.json");

const DateTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
}));

const DateElement = ({ date, missingMessage, textProps = {} }) => {
  if (date) {
    const Sugar = require("sugar");
    const dateActual = new Date(date);
    return (
      <Typography sx={{ ...textProps }}>
        {Sugar.Date.format(
          dateActual,
          "{Weekday} - {Mon} {do}, {yyyy} {h}:{mm} {TT}"
        )}
      </Typography>
    );
  } else {
    return (
      <Typography fontStyle={"italic"} sx={{ ...textProps }}>
        {missingMessage}
      </Typography>
    );
  }
};

const DueDate = ({ courseConfig, disable }) => {
  // @ts-ignore
  const isStudentView = useStudentView();
  const [mouseOver, setMouseOver] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  return useMemo(
    () => (
      <DateTooltip
        title={
          <Fragment>
            <Grid
              container
              spacing={0.5}
              alignItems="center"
              onClick={(e) => e.stopPropagation()}
            >
              <Grid xs={5} item>
                <Typography color="inherit" fontSize="inherit" fontWeight={700}>
                  {
                    TEXT.components.course_schedule.due_date
                      .release_date_tooltip_title
                  }
                </Typography>
              </Grid>
              <Grid xs={7} item>
                <DateElement
                  textProps={{ color: "inherit", fontSize: "inherit" }}
                  date={courseConfig.release}
                  missingMessage={
                    TEXT.components.course_schedule.due_date.no_release_date
                  }
                />
              </Grid>
              <Grid xs={5} item>
                <Typography color="inherit" fontSize="inherit" fontWeight={700}>
                  {
                    TEXT.components.course_schedule.due_date
                      .due_date_tooltip_title
                  }
                </Typography>
              </Grid>
              <Grid xs={7} item>
                <DateElement
                  textProps={{ color: "inherit", fontSize: "inherit" }}
                  date={courseConfig.due}
                  missingMessage={
                    TEXT.components.course_schedule.due_date.no_due_date
                  }
                />
              </Grid>
              <Grid xs={5} item>
                <Typography color="inherit" fontSize="inherit" fontWeight={700}>
                  {
                    TEXT.components.course_schedule.due_date
                      .until_date_tooltip_title
                  }
                </Typography>
              </Grid>
              <Grid xs={7} item>
                <DateElement
                  textProps={{ color: "inherit", fontSize: "inherit" }}
                  date={courseConfig.until}
                  missingMessage={
                    TEXT.components.course_schedule.due_date.no_until_date
                  }
                />
              </Grid>
            </Grid>
          </Fragment>
        }
        describeChild
        placement="right"
        arrow
      >
        <Paper
          onMouseEnter={() => {
            if (!isStudentView && !disable) setMouseOver(true);
          }}
          onMouseLeave={() => {
            if (!isStudentView && !disable) setMouseOver(false);
          }}
          sx={{
            cursor: !disable ? "pointer" : "inherit",
            background: "inherit",
            border: "solid",
            borderColor: mouseOver ? GT_COLORS.primaryDarkBlue : "transparent",
            borderRadius: mouseOver ? "5px" : "none",
            borderWidth: "1px",
          }}
          elevation={0}
          onClick={(e) => {
            if (!isStudentView) {
              navigate(
                makePath(
                  `/problem-set/${courseConfig.problem_set_id}/edit/${TAB_COURSES}/${courseConfig.group_id}`,
                  searchParams
                )
              );
              e.stopPropagation();
            }
          }}
        >
          <Box sx={{ p: 2 }}>
            <DateElement
              date={courseConfig.due}
              missingMessage={
                TEXT.components.course_schedule.due_date.no_due_date
              }
            />
          </Box>
        </Paper>
      </DateTooltip>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      courseConfig.due,
      courseConfig.problem_set_id,
      courseConfig.release,
      isStudentView,
      mouseOver,
    ]
  );
};

export default DueDate;

/**
 * The container that holds a specific test-action component and the
 * test-action's options component
 */

import {
  Box,
  CircularProgress,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  DownIcon,
  OptionsVerticalIcon,
  TrashIcon,
  UpIcon,
} from "../../styles/icons";
import IconButton from "../IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GT_COLORS } from "../../styles/Styles";
const { useState } = require("react");
const TEXT = require("../../text.json");

const ScheduleOptionsMenu = ({
  courseConfig,
  handleRemove = (courseConfig, completeCallback) => {},
  handleMove = (placement, courseConfig, completeCallback) => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return (
      <Box
        sx={{
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          width: 1,
        }}
      >
        <CircularProgress
          // @ts-ignore
          color="primaryDarkBlue"
          size={"1.2rem"}
        />
      </Box>
    );
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        icon={OptionsVerticalIcon}
        size={"1.2rem"}
        tooltip={
          TEXT.components.course_schedule.schedule_options_menu
            .test_setting_button_tooltip
        }
        onClick={(e) => {
          handleClick(e);
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            setLoading(true);
            handleMove('up', courseConfig, () => {
              setLoading(false);
            });
          }}
        >
          <ListItemIcon>
            <UpIcon color={GT_COLORS.primaryDarkBlue} />
          </ListItemIcon>
          <ListItemText>
            {
              TEXT.components.course_schedule.schedule_options_menu
                .option_menu_move_up_option
            }
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setLoading(true);
            handleMove('down', courseConfig, () => {
              setLoading(false);
            });
          }}
        >
          <ListItemIcon>
            <DownIcon color={GT_COLORS.primaryDarkBlue} />
          </ListItemIcon>
          <ListItemText>
            {
              TEXT.components.course_schedule.schedule_options_menu
                .option_menu_move_down_option
            }
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setLoading(true);
            handleRemove(courseConfig, () => {
              setLoading(false);
            });
          }}
        >
          <ListItemIcon>
            <TrashIcon color={GT_COLORS.primaryDarkBlue} />
          </ListItemIcon>
          <ListItemText>
            {
              TEXT.components.course_schedule.schedule_options_menu
                .option_menu_remove_option
            }
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

ScheduleOptionsMenu.defaultProps = {};

export default ScheduleOptionsMenu;

import { isEmpty } from "../utils";

const SCHEMAS_JOI = {};
const AJV_HOLDER = {};

export const getSchema = ({ objectName, jsonSchema}) => {
  const Ajv = require("ajv").default;
  const addFormats = require("ajv-formats").default;

  if (!AJV_HOLDER.ajv) {
    // @ts-ignore
    AJV_HOLDER.ajv = new Ajv();
    addFormats(AJV_HOLDER.ajv);
  }

  const schema = SCHEMAS_JOI[objectName];

  if (!schema) {
    const actSchema = jsonSchema[objectName];
    const joiSchema = AJV_HOLDER.ajv.compile(actSchema);
    SCHEMAS_JOI[objectName] = { validate: joiSchema };
    return SCHEMAS_JOI[objectName];
  }

  return schema;
};

import GlobalStyles from "@mui/material/GlobalStyles";
import { createTheme } from "@mui/material/styles";

export const GT_COLORS = {
  primaryDarkBlue: "#070D59",
  altMidBlue: "#3969a6",
  primaryLightBlue: "#4178bc",
  primaryGrey: "#eeeeee",
  textFieldFocusBackground: "#fff",
  altMidGrey: "#e2e2e2",
  actionYellow: "#F0C457",
  actionYellowOver: "#ebaf1b",
  activeYellowDisabled: "fade(#F0C457, 20%);",
};

export const FONT_FAMILY = [
  "Poppins",
  "Helvetica",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

export const CODE_FONT_FAMILY = ["Courier New", "monospace"].join(",");

const TEXT_STYLE = {
  color: GT_COLORS.primaryDarkBlue,
  fontFamily: FONT_FAMILY,
};

const GT_CSS = {
  h1: TEXT_STYLE,
  h2: TEXT_STYLE,
  h3: TEXT_STYLE,
  h4: TEXT_STYLE,
  h5: TEXT_STYLE,
  p: TEXT_STYLE,
  label: TEXT_STYLE,
  li: TEXT_STYLE,
  a: TEXT_STYLE,
  body: { backgroundColor: GT_COLORS.primaryGrey, ...TEXT_STYLE },
};

export const CONTENT_LOADER_DEFAULT_PROPS = {
  backgroundColor: "#d9e4fc",
  foregroundColor: GT_COLORS.primaryGrey,
};

export const MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  backgroundColor: GT_COLORS.primaryGrey,
  boxShadow: 24,
  pt: 2,
  pr: 4,
  pl: 4,
  pb: 4,
  outline: "none",
  borderRadius: 1.4,
  whiteSpace: "normal",
  //overflow: "auto",
};
export const MAX_WIDTH = 1700;
export const GLOBAL_CSS = <GlobalStyles styles={GT_CSS} />;
const FONT_SIZE = 16;
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
export const GT_THEME = createTheme({
  palette: {
    primaryDarkBlue: createColor(GT_COLORS.primaryDarkBlue),
    altMidBlue: createColor(GT_COLORS.altMidBlue),
    primaryLightBlue: createColor(GT_COLORS.primaryLightBlue),
    primaryGrey: createColor(GT_COLORS.primaryGrey),
    altMidGrey: createColor(GT_COLORS.altMidGrey),
    text: {
      primary: GT_COLORS.primaryDarkBlue,
      secondary: GT_COLORS.primaryDarkBlue,
    },
  },
  typography: {
    color: GT_COLORS.primaryDarkBlue,
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZE,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: GT_COLORS.altMidBlue,
          fontSize: 14,
        },
        arrow: {
          color: GT_COLORS.altMidBlue,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: { fontSize: FONT_SIZE },
        root: {
          "&.Mui-focused": {
            backgroundColor: GT_COLORS.textFieldFocusBackground,
          },

          "& fieldset": {
            borderColor: GT_COLORS.primaryLightBlue,
          },
          // '&:hover fieldset': {
          //   borderColor: 'white',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'yellow',
          // },
        },
      },
    },
  },
});

export const SPLIT_DIVIDER_COLORS = {
  color: GT_COLORS.primaryLightBlue,
  hover: GT_COLORS.altMidGrey,
  drag: GT_COLORS.primaryDarkBlue,
};

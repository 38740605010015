import PropTypes from "prop-types";
import { Divider, Stack, Typography } from "@mui/material";
import { GT_COLORS } from "../../styles/Styles";
import { useSelector } from "react-redux";
import { useStudentView } from "../../utils";
import AddProblemSetButton from "./AddProblemSetButton";
const TEXT = require("../../text.json");

/**
 * NoProblemSets is a component that displays a message indicating that there
 * are no problem sets. If the user is a teacher and a courseId is provided, it
 * also displays a button to add a new problem set.
 *
 * @param {object} props Component props
 * @param {string|null} [props.courseId=null] The unique identifier for the
 * course. The 'Add Problem Set' button is only shown if this is provided.
 */
const NoProblemSets = ({ courseId }) => {
  // @ts-ignore
  const isTeacher = useSelector((state) => state.user.userInfo.isTeacher);
  const isStudentView = useStudentView();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
      sx={{
        border: "solid",
        borderColor: GT_COLORS.primaryLightBlue,
        borderRadius: "5px",
        borderWidth: "1px",
        width: "90%",
        p: 4,
      }}
      divider={<Divider orientation="horizontal" flexItem />}
    >
      <Typography sx={{ fontSize: "1.4rem", textAlign: "center" }}>
        {isTeacher
          ? TEXT.components.course_schedule.no_problem_sets.teacher_message
          : TEXT.components.course_schedule.no_problem_sets.student_message}
      </Typography>
      {!isStudentView && courseId && (
        <AddProblemSetButton courseId={courseId} />
      )}
    </Stack>
  );
};

NoProblemSets.propTypes = {
  courseId: PropTypes.string,
};

export default NoProblemSets;

/**
 * Returns a number based on the proximity of the provided number to the values
 * in an array, adjusted by the specified addValue. Used for reordering course
 * configs within the same course.
 *
 * @param {number[]} arr - The array of numbers to compare against.
 * @param {number} num - The number to find the next closest value to.
 * @param {number} addValue - The value to add to the resulting number. If
 * negative, the function will look for a smaller number in the array.
 * @returns {number} The adjusted closest number.
 *
 * @example
 * nextClosest([1,2,5,6], 3, 1)    // returns 6
 * nextClosest([1,2,5,6], 7, 1)    // returns 7
 * nextClosest([1,2,5,6], 3, -1)   // returns 1
 * nextClosest([1,2,5,6], 1, -1)   // returns 0
 */
export function nextClosest(arr, num, addValue) {
  // Sort the array in ascending order
  arr.sort((a, b) => a - b);

  // If addValue is positive
  if (addValue > 0) {
    // If the given number is larger than the largest number in the array
    if (num > arr[arr.length - 1]) {
      return num;
    }

    if (num === arr[arr.length - 1]) {
      return num + addValue;
    }

    // Find the first number in the array which is larger than the given number
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] > num) {
        return arr[i] + addValue;
      }
    }
  } else {
    // If the given number is smaller than the smallest number in the array
    if (num < arr[0]) {
      return num;
    }

    if (num === arr[0]) {
      return num + addValue;
    }

    // Find the first number in the array which is smaller than the given number
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i] < num) {
        return arr[i] + addValue;
      }
    }
  }
}

/**
 * Sorts an array of Course Configurations based on the properties: order, due,
 * name, and created_at.
 *
 * @param {Object[]} arr - The array of Course Configurations to sort.
 * @returns {Object[]} The sorted array.
 */
export function sortCourseConfigs(arr) {
  return arr.sort((a, b) => {
    if (a.order !== b.order) {
      return (a.order || 0) - (b.order || 0); // ascending by order
    }

    // Convert due strings to Date objects and compare
    if (a.due && b.due) {
      const dueA = new Date(a.due);
      const dueB = new Date(b.due);
      if (dueA !== dueB) {
        return dueA.getTime() - dueB.getTime(); // ascending by due date
      }
    }

    // Compare names
    if (a.name !== b.name) {
      return a.name.localeCompare(b.name); // ascending by name (alphabetic)
    }

    // Convert created_at strings to Date objects and compare
    if (a.created_at && b.created_at) {
      const createdAtA = new Date(a.created_at);
      const createdAtB = new Date(b.created_at);
      return createdAtA.getTime() - createdAtB.getTime(); // ascending by created_at date
    }

    return 0;
  });
}

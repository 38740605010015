/**
 * Constructs a URL string with optional query and hash parameters, tailored for editing tabs.
 * It incorporates elements like base URL, activity, tab name, and optional IDs.
 *
 * @function
 * @param {Object} options - Configuration options for the function.
 * @param {string} options.tabName - The name of the tab to be embedded in the URL.
 * @param {string} options.problemSetId - The problem set ID to include in the URL.
 * @param {string} [options.resourceId=null] - Optional ID for a resource to be included in the URL.
 * @param {string} [options.testId=null] - Optional test ID to be included in the URL. Requires `resourceId` to be present.
 *
 * @throws {Error} Throws an error if `testId` is provided without a `resourceId`.
 *
 * @returns {string} A newly created URL string based on the provided options and current window location.
 */
export const getEditTabbedUrl = ({
  tabName,
  problemSetId,
  resourceId = null,
  testId = null,
}) => {
  const currentLocation = window.location;
  let newPath = `/problem-set/${problemSetId}/edit`;

  newPath += `/${tabName}`;

  if (resourceId) {
    newPath += `/${resourceId}`;
  }

  if (testId && !resourceId) {
    throw Error("A resourceId must be specified in order to use a testId");
  }

  if (testId) {
    newPath += `/test/${testId}`;
  }

  if (currentLocation.search) {
    newPath += `${currentLocation.search}`;
  }

  if (currentLocation.hash) {
    newPath += currentLocation.hash;
  }

  return newPath;
};

/**
 * Constructs a URL string with optional query and hash parameters, tailored for editing tabs.
 * It incorporates elements like base URL, activity, tab name, and optional IDs.
 *
 * @function
 * @param {Object} options - Configuration options for the function.
 * @param {"submit"|"result"} options.tabName - The name of the tab to be embedded in the URL.
 * @param {string} options.problemSetId - The problem set ID to include in the URL.
 * @param {string} options.courseId - The course hash ID to include in the URL.
 * @param {string} [options.problemId=null] - Optional ID for a problem to be included in the URL.
 * @param {string} [options.submissionId=null] - Optional submission ID to be
 * included in the URL. Requires `tabName` to equal 'submit'.
 *
 * @throws {Error} Throws an error if `submissionId` and tabName is not equal to
 * "submit".
 *
 * @returns {string} A newly created URL string based on the provided options and current window location.
 */
export const getSubmitTabbedUrl = ({
  tabName,
  problemSetId,
  courseId,
  problemId = null,
  submissionId = null,
}) => {
  const currentLocation = window.location;
  let newPath = `/problem-set/${problemSetId}/submit/${courseId}`;

  newPath += `/${tabName}`;

  if (problemId) {
    newPath += `/${problemId}`;
  }

  if (submissionId && tabName !== "submit") {
    throw Error("The tab name must bew 'submit' if submissionId is specified");
  }

  if (submissionId) {
    newPath += `/${submissionId}`;
  }

  if (currentLocation.search) {
    newPath += `${currentLocation.search}`;
  }

  if (currentLocation.hash) {
    newPath += currentLocation.hash;
  }

  return newPath;
};

import { useState } from "react";
import IconButton from "../IconButton";
import { CopyOutlineIcon } from "../../styles/icons";
import { useDispatch } from "react-redux";
import { showToast } from "../../slices/error";
const TEXT = require("../../text.json");

export default function CodeCopyBtn({ children }) {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    console.log('CODE:', children[0])
    navigator.clipboard
      .writeText(children[0].props.children[0])
      .then(() => {
        // Successful copy
        dispatch(
          showToast({
            message: TEXT.components.markdown_copy_button.code_copied_success,
            severity: "success",
            autoHideDuration: 5 * 1000,
          })
        );
      })
      .catch((e) => {
        // Failed copy
        console.error("Failed to copy code: ", e);
        showToast({
          message: TEXT.components.markdown_copy_button.code_copied_failure,
        });
      });
  };
  return (
    <IconButton
      icon={CopyOutlineIcon}
      tooltip={TEXT.components.markdown_copy_button.tooltip}
      size={"1.1rem"}
      onClick={handleClick}
    />
  );
}

import { ErrorBoundary } from "react-error-boundary";
import GeneralBarrier from "../pages/GeneralBarrier"; // Do not put this in component loadable
import { FaceSadCryIcon } from "../styles/icons";
import { checkFrontEndConnectivity } from "../api/crud";
import { useMemo, useState } from "react";
import { Logtail } from "@logtail/browser";
import { useSelector } from "react-redux";

const TEXT = require("../text.json");

const ErrorBarrier = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState(
    TEXT.general_barrier.error_message
  );
  // @ts-ignore
  const userInfo = useSelector((state) => state.user.userInfo);

  return useMemo(
    () => (
      <ErrorBoundary
        fallback={
          <GeneralBarrier
            message={errorMessage}
            icon={FaceSadCryIcon}
            reload={true}
          />
        }
        onError={(error, info) => {
          console.error(error, info);
          // Check that the server is connectable
          if (error.name === "ChunkLoadError") {
            checkFrontEndConnectivity().then((up) => {
              // The server is up let's refresh to get the updates
              if (up) {
                console.error("The server is up");
                window.location.reload();
              } else {
                console.error("The server is down");
                // The servers are down so we can't refresh
                setErrorMessage(
                  TEXT.general_barrier.error_message_frontend_down
                );
              }
            });
          } else {
            if (window["agt_log_token"]) {
              const logtail = new Logtail(window["agt_log_token"]);
              logtail.error(error.toString(), {
                user_id: userInfo?.userId,
                error_info: info,
              });
            }
          }
        }}
      >
        {children}
      </ErrorBoundary>
    ),
    [children, errorMessage]
  );
};

export default ErrorBarrier;

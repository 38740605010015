import { BiEditAlt, BiSolidLock } from "react-icons/bi";
import {
  BsLayoutTextSidebarReverse,
  BsListCheck,
  BsTrash,
  BsKeyboard,
  BsSpellcheck,
  BsSunFill,
  BsFillMoonStarsFill,
} from "react-icons/bs";
import {
  LuCalendarClock,
  LuPlusCircle,
  LuSettings,
  LuSpace,
} from "react-icons/lu";
import {
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaChevronDown,
  FaSearch,
  FaCode,
  FaRegCopy,
  FaHourglassHalf,
  FaSadCry,
} from "react-icons/fa";
import { BiHide, BiShow, BiError } from "react-icons/bi";
import { VscInfo, VscNewline } from "react-icons/vsc";
import { BiSolidError } from "react-icons/bi";
import { TbError404, TbNumbers } from "react-icons/tb";
import {
  MdOutlineOutput,
  MdErrorOutline,
  MdOutlineInput,
  MdKeyboardTab,
  MdClose,
  MdOutlineFavorite,
  MdOutlineFavoriteBorder,
  MdOutlineStopCircle,
  MdOutlineRedo,
  MdOutlineUndo,
  MdFullscreen,
  MdFullscreenExit
} from "react-icons/md";
import { FaThumbsUp, FaEllipsisV, FaFlask } from "react-icons/fa";
import {
  RiCheckboxCircleFill,
  RiCheckboxCircleLine,
  RiCloseCircleFill,
  RiPushpinFill,
  RiUnpinFill,
} from "react-icons/ri";
import { GiBookCover } from "react-icons/gi";
import { CgNotes, CgRemove } from "react-icons/cg";
import { ReactComponent as CIcon } from "../assets/language-icons/c.svg";
import { ReactComponent as CppIcon } from "../assets/language-icons/cpp.svg";
import { ReactComponent as CSharpIcon } from "../assets/language-icons/csharp.svg";
import { ReactComponent as CssIcon } from "../assets/language-icons/css.svg";
import { ReactComponent as SqlIcon } from "../assets/language-icons/sql.svg";
import { ReactComponent as DockerIcon } from "../assets/language-icons/docker.svg";
import { ReactComponent as HtmlIcon } from "../assets/language-icons/html.svg";
import { ReactComponent as JavaIcon } from "../assets/language-icons/java.svg";
import { ReactComponent as JavascriptIcon } from "../assets/language-icons/javascript.svg";
import { ReactComponent as JuliaIcon } from "../assets/language-icons/julia.svg";
import { ReactComponent as RustIcon } from "../assets/language-icons/rust.svg";
import { ReactComponent as GoIcon } from "../assets/language-icons/go.svg";
import { ReactComponent as PythonIcon } from "../assets/language-icons/python.svg";
import { HiOutlineLightBulb, HiOutlineTerminal } from "react-icons/hi";
import { PiClockClockwiseBold, PiStudent } from "react-icons/pi";
import { AiOutlineCloudUpload, AiOutlineFontColors, AiOutlineHighlight, AiOutlineUnderline } from "react-icons/ai";
import { FiSunset, FiSunrise } from "react-icons/fi";
import { ImLink } from "react-icons/im";

import { Box } from "@mui/material";

export const EditIcon = BiEditAlt;
export const SubmissionIcon = BsListCheck;
export const SettingsIcon = LuSettings;
export const BackIcon = FaChevronLeft;
export const ForwardIcon = FaChevronRight;
export const UpIcon = FaChevronUp;
export const DownIcon = FaChevronDown;
export const ErrorIcon = BiError;
export const SolidTriangleErrorIcon = BiSolidError;
export const CircleErrorIcon = MdErrorOutline;
export const ShowIcon = BiShow;
export const HideIcon = BiHide;
export const OutputIcon = MdOutlineOutput;
export const InputIcon = MdOutlineInput;
export const InfoIcon = VscInfo;
export const ThumbsUp = FaThumbsUp;
export const CircleCheckFilledIcon = RiCheckboxCircleFill;
export const CircleCheckOutlineIcon = RiCheckboxCircleLine;
export const CircleX = RiCloseCircleFill;
export const SearchIcon = FaSearch;
export const LightbulbIcon = HiOutlineLightBulb;
export const CodeIcon = FaCode;
export const LogsIcon = CgNotes;
export const PendingIcon = PiClockClockwiseBold;
export const AddOutlinedIcon = LuPlusCircle;
export const UploadOutlinedIcon = AiOutlineCloudUpload;
export const OutlineTerminalIcon = HiOutlineTerminal;
export const RemoveIcon = CgRemove;
export const DescriptionIcon = BsLayoutTextSidebarReverse;
export const NewLineIcon = VscNewline;
export const SpaceKeyboardIcon = LuSpace;
export const TabKeyboardIcon = MdKeyboardTab;
export const CloseIcon = MdClose;
export const OptionsVerticalIcon = FaEllipsisV;
export const TrashIcon = BsTrash;
export const KeyboardIcon = BsKeyboard;
export const FlaskIcon = FaFlask;
export const NumbersIcon = TbNumbers;
export const SpellCheck = BsSpellcheck;
export const CopyOutlineIcon = FaRegCopy;
export const SunsetIcon = FiSunset;
export const SunriseIcon = FiSunrise;
export const SunFilledIcon = BsSunFill;
export const MoonStarsFilledIcon = BsFillMoonStarsFill;
export const CalendarClockIcon = LuCalendarClock;
export const PinIcon = RiPushpinFill;
export const UnpinIcon = RiUnpinFill;
export const FavoriteOutlineIcon = MdOutlineFavoriteBorder;
export const FavoriteIcon = MdOutlineFavorite;
export const StudentIcon = PiStudent;
export const HourGlass = FaHourglassHalf;
export const LockIcon = BiSolidLock;
export const StopCircleOutlinedIcon = MdOutlineStopCircle;
export const LinkIcon = ImLink;
export const FaceSadCryIcon = FaSadCry;
export const Missing404Icon = TbError404;
export const BookCoverIcon = GiBookCover;
export const OutlineUndo = MdOutlineUndo;
export const OutlineRedo = MdOutlineRedo;
export const OutlineHighlight = AiOutlineHighlight;
export const OutlineFontColors = AiOutlineFontColors;
export const OutlineUnderline = AiOutlineUnderline;
export const FullscreenIcon = MdFullscreen;
export const FullscreenExitIcon = MdFullscreenExit;

export const LanguageIcon = (languageName, iconSize) => {
  const Icon = (IconName, sx) => (
    <Box sx={sx}>
      <IconName />
    </Box>
  );

  const languageIcons = {
    java: Icon(JavaIcon, { height: iconSize, width: iconSize }),
    python: Icon(PythonIcon, { height: iconSize, width: iconSize }),
    javascript: Icon(JavascriptIcon, { height: iconSize, width: iconSize }),
    golang: Icon(GoIcon, { height: iconSize, width: iconSize }),
    html: Icon(HtmlIcon, { height: iconSize, width: iconSize }),
    dockerfile: Icon(DockerIcon, { height: iconSize, width: iconSize }),
    sql: Icon(SqlIcon, { height: iconSize, width: iconSize }),
    "c#": Icon(CSharpIcon, { height: iconSize, width: iconSize }),
    julia: Icon(JuliaIcon, { height: iconSize, width: iconSize }),
    rust: Icon(RustIcon, { height: iconSize, width: iconSize }),
    c: Icon(CIcon, { height: iconSize, width: iconSize }),
    cpp: Icon(CppIcon, { height: iconSize, width: iconSize }),
    css: Icon(CssIcon, { height: iconSize, width: iconSize }),
  };

  return languageIcons[languageName] || FaCode;
};
